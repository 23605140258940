import qs from 'qs';
import dataModel from '../../models/data';
import shortClientId from '../shortClientId';

export function getOauthClientImage(clientId: string): string {
  return `${dataModel.assetsPath}${shortClientId(clientId)}/logo.png`;
}

function convertSnakeToKebabCase(string: string): string {
  const arr = string.split('_');
  let str = arr[0];

  for (let i = 1; i < arr.length; i += 1) {
    const value = arr[i];

    if (value) {
      str += value[0].toUpperCase() + value.substr(1);
    }
  }

  return str;
}

export function qsConfigs(keepKeyCase = false): {
  [key: string]: boolean | string | string[];
} {
  let { configs, show_remember_me: showRememberMe } = qs.parse(
    window.location.search,
    { ignoreQueryPrefix: true }
  );

  configs = (
    Array.isArray(configs) ? configs[configs.length - 1] : configs
  ) as string;
  showRememberMe = (
    Array.isArray(showRememberMe)
      ? showRememberMe[showRememberMe.length - 1]
      : showRememberMe
  ) as string;

  let parsedShowRememberMe: boolean | undefined;

  // if defined, every value will be true unless it is false
  if (showRememberMe !== undefined) {
    parsedShowRememberMe = showRememberMe.toString() !== 'false';
  }

  if (!configs) {
    // TODO: deprecate this
    // use legacy show_remember_me value if exists
    if (parsedShowRememberMe !== undefined) {
      return { showRememberMe: parsedShowRememberMe };
    }

    return {};
  }

  // TODO: deprecate the use of `;`
  const delimiter = configs.indexOf('&') === -1 ? ';' : '&';

  const processedConfigs: { [key: string]: boolean | string | string[] } = {};

  const configsValue = qs.parse(configs, { delimiter });

  for (const key in configsValue) {
    // istanbul ignore next
    if (!configsValue.hasOwnProperty(key)) {
      continue;
    }

    const value = configsValue[key];

    // TODO: what's wrong with arrays here? why do we have this special handling?
    let parsedValue = (
      Array.isArray(value) ? value[value.length - 1] : value
    ) as string | boolean | string[];

    // return as boolean if the string is `true` or `false`
    if (parsedValue === 'true') {
      parsedValue = true as boolean;
    } else if (parsedValue === 'false') {
      parsedValue = false as boolean;
    }

    const emailValue = parsedValue as string;

    // TODO: deprecate this, special characters was not escaped in some email in old sdk
    if (key === 'email' && emailValue.length) {
      parsedValue = emailValue.replace(' ', '+') as string;
    }

    // TODO: we really shouldn't have special handling here... see line 70
    if (key === 'authn_method' && Array.isArray(value)) {
      parsedValue = value as string[];
    }

    const convertedKey = keepKeyCase ? key : convertSnakeToKebabCase(key);
    processedConfigs[convertedKey] = parsedValue;
  }

  // TODO: deprecate this
  // use legacy show_remember_me value if exists
  if (parsedShowRememberMe !== undefined) {
    processedConfigs.showRememberMe = parsedShowRememberMe;
  }

  return processedConfigs;
}

export function updateQueryString(
  key: string,
  value: string | number | boolean
) {
  const queryString = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });
  queryString[key] = value.toString();

  window.history.replaceState(
    null,
    document.title,
    qs.stringify(queryString, { addQueryPrefix: true })
  );
}

export function appendOauthQueries(
  url: string,
  queries: { [key: string]: string | number | boolean | undefined }
): string {
  const index = url.indexOf('#');
  const hash = index !== -1 ? url.slice(index) : '';
  // `substring` is to support the case which hash has a value with query included
  // e.g. http://google.com#back_to=settings?display_mode=dark
  const separator = (url.substring(0, index) || url).includes('?') ? '&' : '?';

  return `${url.replace(hash, '')}${separator}${qs.stringify(queries)}${hash}`;
}

export const __TEST__ = {
  convertSnakeToKebabCase
};
