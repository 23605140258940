import toastsModel from '../../../models/toasts';
import { Preset } from '../../../components/Toast';
import { updatePartnerConfig } from '../../../services/client-info';

export default function<T>(clientId: string, country: string, configKey: string, value: T) {
  return updatePartnerConfig<T>({
    clientId,
    country,
    configKey,
    configValue: value
  })
    .then(() => {
      toastsModel.add({
        text: 'Partner configuration updated successfully.'
      });

      return true;
    })
    .catch(() => {
      toastsModel.add({
        text: 'Failed to update partner configuration.',
        preset: Preset.Danger
      });

      return false;
    });
}
