import React, { PropsWithChildren, ButtonHTMLAttributes } from 'react';
import classnames from 'classnames';

import './styles.scss';

export enum Preset {
  None = '',
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
  Negative = 'negative'
}

export interface IProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  preset?: Preset;
  onPress?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function Button(props: PropsWithChildren<IProps>) {
  const { className, type = 'button', disabled = false, onPress, preset = Preset.None, ...remainingProps } = props;

  return (
    <button
      className={classnames('button', className, preset, { disabled })}
      type={type}
      disabled={disabled}
      onClick={onPress}
      {...remainingProps}
    />
  );
}
