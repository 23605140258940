import { observable } from 'mobx';
import i18next, { StringMap, TOptions } from 'i18next';
import { Preset, IProps as IToastProps } from '../components/Toast';

const TOAST_TIMEOUT = 5 * 1000;

interface IToastCodeProps {
  code: string;
  value: TOptions<StringMap>;
  preset?: Preset;
}

export interface IToastModelProps {
  id?: string;
  text: string;
  preset?: Preset;
}

function toastCodeToLangCode(code: string) {
  const strings = code.split('_');
  let langCode = strings[0];

  for (let i = 1; i < strings.length; i += 1) {
    const string = strings[i];
    langCode = `${langCode}${string[0].toUpperCase()}${string.substr(1)}`;
  }

  return langCode;
}

class Toasts {
  @observable public toasts: IToastProps[] = [];
  // this attribute keep tracks of toasts that we received on page load
  // it is useful for application to know if the page load was a redirection from a previous action
  public history: IToastProps[] = [];

  constructor() {
    this.reset();
  }

  public add(toast: IToastModelProps) {
    if (!toast) {
      return;
    }

    if (toast.text === '') {
      return;
    }

    const toastProps = {
      ...toast,
      id: toast.id || this.generateId()
    };

    this.toasts.unshift(toastProps);
    this.history.unshift(toastProps);

    setTimeout(() => this.remove(), TOAST_TIMEOUT);
  }

  public multiAdd(toasts: IToastModelProps[] = []) {
    if (!Array.isArray(toasts)) {
      return;
    }

    for (const toast of toasts) {
      this.add(toast);
    }
  }

  public addCode(toast: IToastCodeProps) {
    if (!toast) {
      return;
    }

    const langCode = toastCodeToLangCode(toast.code);
    const text = i18next.t(`Toast.${langCode}`, toast.value);

    const toastData = {
      text,
      id: this.generateId(),
      preset: toast.preset
    };

    this.toasts.unshift(toastData);
    this.history.unshift(toastData);

    setTimeout(() => this.remove(), TOAST_TIMEOUT);
  }

  public multiAddCode(toasts: IToastCodeProps[] = []) {
    if (!Array.isArray(toasts)) {
      return;
    }

    for (const toast of toasts) {
      this.addCode(toast);
    }
  }

  private reset() {
    this.toasts = [];
  }

  private generateId(): string {
    return (Date.now() + Math.random()).toString();
  }

  private remove() {
    const toasts = this.toasts;

    if (toasts.length) {
      toasts.pop();
    }
  }
}

export default new Toasts();
