import React from 'react';
import semver from 'semver';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

import { qsConfigs } from '../../lib/helpers';
import isValidUrl from '../../lib/is-valid-url';

import dataModel from '../data';

function replaceBackToKeyWithImportantParams(backTo: string): string {
  const parameters = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const keys = Object.keys(parameters);

  let returnedBackTo = backTo;

  for (const key of keys) {
    let value = parameters[key];
    value = Array.isArray(value) ? value[value.length - 1] : value;

    returnedBackTo = returnedBackTo.replace(`\${${key}}`, value as string);
  }

  return returnedBackTo;
}

function getBackToUrl(isDesktop = true): string {
  // no header for url with /settings/* path in mobile view
  if (!isDesktop && window.location.pathname.match(/^\/settings\//)) {
    return '';
  }

  // TODO: remove "redirect_uri" as "back_to" fallback when there is no more less than SDK v5
  const { redirect_uri: redirectUriQs, continue: continueToQs } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });
  const { backTo, sdkPlatform, sdkVersion } = qsConfigs();
  const redirectUri = Array.isArray(redirectUriQs) ? redirectUriQs[redirectUriQs.length - 1] : redirectUriQs;
  const continueTo = Array.isArray(continueToQs) ? continueToQs[continueToQs.length - 1] : continueToQs;

  // no back_to and redirect_uri query param config
  if (!backTo && !redirectUri) {
    return '';
  }

  const isNativeSDK = sdkPlatform === 'ios' || sdkPlatform === 'android';

  // no header for native sdk >= 5
  if (isNativeSDK && sdkVersion && semver.gte(sdkVersion as string, '5.0.0')) {
    return '';
  }

  // do not show for non-native sdk and when redirect_uri exists but not back_to
  if (!isNativeSDK && !backTo && redirectUri) {
    return '';
  }

  const { clientApp, cobrandClientApp } = dataModel;

  // no client_id
  if (clientApp.uid === '') {
    return '';
  }

  // oauth page have a cancel button, so no need back to header
  if (window.location.pathname === '/oauth/authorize') {
    return '';
  }

  // client_id is mt-app and (cobrand_client_id is mt-app or no cobrand/client_id) and guest not logged in
  if (clientApp.isMTClient && (cobrandClientApp.isMTClient || cobrandClientApp.uid === '') && !dataModel.uid) {
    return '';
  }

  // only allow valid urls
  const backToUrl = (backTo || redirectUri) as string
  if (!isValidUrl(backToUrl)) {
    return ''
  }

  const [url, query] = replaceBackToKeyWithImportantParams(backToUrl).split('?');

  // add continue query parameter
  const parsedQuery = qs.parse(query);
  parsedQuery.continue = continueTo;
  const finalQuery = qs.stringify(parsedQuery);

  if (finalQuery) {
    return `${url}?${finalQuery}`;
  } else {
    return url;
  }
}

export interface IBackToHeaderContext {
  backToUrl: string;
}

export const BackToHeaderContext = React.createContext<IBackToHeaderContext>({
  backToUrl: ''
});

interface IBackToHeaderProviderProps {
  isDesktop?: boolean;
  children?: React.ReactNode;
}

export default function BackToHeaderProvider(props: IBackToHeaderProviderProps) {
  const { children, isDesktop = false } = props;
  const history = useHistory();
  const [backToUrl, setBackToUrl] = React.useState('');

  // update visibility state when screen size changes
  React.useEffect(() => {
    setBackToUrl(getBackToUrl(isDesktop));

    // also update visibility state on route change since different routes can have different conditions to display
    history.listen(() => {
      setBackToUrl(getBackToUrl(isDesktop));
    });
  }, [isDesktop]);

  return <BackToHeaderContext.Provider value={{ backToUrl }}>{children}</BackToHeaderContext.Provider>;
}

export const __TEST__ = {
  getBackToUrl,
  replaceBackToKeyWithImportantParams
};
