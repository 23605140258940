import { RequestOptions } from 'mixpanel-browser';
import { Base } from './base';
import { LOGIN_METHOD_VALUE_ENUM } from './login';

function fastOnboardingLanding(this: Base) {
  this.instance?.track('[fastOnboarding]started', {
    ...this.default_properties
  });
}

function fastOnboardingSignUpButtonClicked(this: Base) {
  this.instance?.track(
    '[fastOnboarding]signupButton_clicked',
    { ...this.default_properties },
    { send_immediately: true } as RequestOptions
  );
}

function fastOnboardingLoginFormLanding(this: Base) {
  this.instance?.track('[fastLogin]started', {
    ...this.default_properties
  });
}

function fastOnboardingLoginWithPasswordLinkClicked(this: Base) {
  this.instance?.track(
    '[fastLogin]password_requested',
    { ...this.default_properties },
    { send_immediately: true } as RequestOptions
  );
}

// either from login with password button or email magic link buttons in various login forms
function fastOnboardingLoginButtonClicked(this: Base, login_method: LOGIN_METHOD_VALUE_ENUM) {
  this.instance?.track(
    '[fastLogin]loginButton_clicked',
    {
      ...this.default_properties,
      method: login_method
    },
    { send_immediately: true } as RequestOptions
  );
}

export default {
  fastOnboardingLanding,
  fastOnboardingSignUpButtonClicked,
  fastOnboardingLoginFormLanding,
  fastOnboardingLoginWithPasswordLinkClicked,
  fastOnboardingLoginButtonClicked
};
