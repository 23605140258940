// auto reload on 500 error

const LOCAL_STORAGE_RELOAD_INTERVAL_ID = 'mt-guest-service-500-reload-interval';
const MAX_RELOAD_ATTEMPT = 20;
const RELOAD_INTERVAL: number = 60 * 1000; // in milliseconds

export default {
  init: () => {
    if (!window.navigator.cookieEnabled) {
      return;
    }

    const localStorageCounter = window.localStorage.getItem(LOCAL_STORAGE_RELOAD_INTERVAL_ID);
    const counter: number = localStorageCounter ? JSON.parse(localStorageCounter) as number : 0;

    if (counter > MAX_RELOAD_ATTEMPT) {
      return;
    }

    window.setTimeout(
      () => {
        window.localStorage.setItem(LOCAL_STORAGE_RELOAD_INTERVAL_ID, JSON.stringify(counter + 1));
        location.reload();
      },
      RELOAD_INTERVAL
    );
  },
  clear: () => {
    if (window.navigator.cookieEnabled) {
      window.localStorage.removeItem(LOCAL_STORAGE_RELOAD_INTERVAL_ID);
    }
  }
};
