import React, { ReactElement, PropsWithChildren } from 'react';
import classnames from 'classnames';
import Switch, { SwitchProps } from '../Switch';

import './styles.scss';

interface ToggleSettingProps extends SwitchProps {
  title: string;
}

export default function ToggleSetting(props: PropsWithChildren<ToggleSettingProps>): ReactElement {
  const { className, title, children, ...rest } = props;

  return (
    <div className={classnames('toggle-setting', className)}>
      <div>
        <span className="title">{title}</span>
        <Switch {...rest} />
      </div>
      <div className="description">{children}</div>
    </div>
  );
}
