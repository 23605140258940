import analytics from '../../../services/analytics';
import React, { ReactElement, lazy, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import appPage from '../../../hoc/appPage';
import dataModel from '../../../models/data';

import './styles.scss';

const Headers = lazy(() => import('../../../components/Headers'));
const AnonymousArea = lazy(() => import('../../../components/AnonymousArea'));
const OAuthForm = lazy(() => import('../../../components/forms/OAuthForm'));
const SSOConsentForm = lazy(() => import('../../../forms/SSO/ConsentForm'));

function OAuthPage(): ReactElement {
  const { t } = useTranslation();
  const {
    clientApp: { isSsoClient },
    customUi: { hasSsoReference }
  } = dataModel;
  const showSsoForm = isSsoClient && !hasSsoReference;

  const onConsented = useCallback((decision) => {
    if (showSsoForm) {
      analytics.ssoConsentFormDecisionButtonClicked(decision);
    } else {
      analytics.consentFormDecisionButtonClicked(decision);
    }
  }, []);

  useEffect(() => {
    if (showSsoForm) {
      analytics.ssoConsentFormLanding();
    } else {
      analytics.consentFormLanding();
    }
  }, []);

  return (
    <AnonymousArea className="o-auth-page">
      {showSsoForm ? <SSOConsentForm onConsented={onConsented}/> : <OAuthForm onConsented={onConsented}/>}
      <Headers title={showSsoForm ? undefined : t('OAuthPage.title')} />
    </AnonymousArea>
  );
}

export default appPage(OAuthPage);
