import qs from 'qs';

import dataModel from '../../models/data';

export default function backToWithAction(action: string) {
  const { backToUrl } = dataModel;

  if (!backToUrl) {
    return;
  }

  const [url, backToQueryStrings] = backToUrl.split('?');
  const backToQueryParams = qs.parse(backToQueryStrings);
  const { state } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  // add magic_link_sent in configs query params
  backToQueryParams.action = action;
  if (state) {
    backToQueryParams.state = state;
  }

  window.location.href = `${url}?${qs.stringify(backToQueryParams)}`;
}
