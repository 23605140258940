import React, { PropsWithChildren, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useHealthStatus } from '../../contexts/healthStatus';
import { usePartnerConfig } from '../../contexts/partnerConfig';
import intercom from '../../lib/Intercom';
import MaintenanceView from '../../views/MaintenanceView';
import UnavailableView from '../../views/UnavailableView';
import appConfig from '../../configs/app.json';
import SpinnerOverlay from '../SpinnerOverlay';

interface StateRenderManagerProps {
  showDefaultIntercom: boolean;
}

// setup and render UI depending on various states such as partner configs or maintenance status
export default function StateRenderManager(
  props: PropsWithChildren<StateRenderManagerProps>
) {
  const { children, showDefaultIntercom } = props;

  const { maintenance, error, setStatus } = useHealthStatus();

  const isDesktop = useMediaQuery({
    minWidth: appConfig.desktopMinWidth,
    minHeight: appConfig.desktopMinHeight
  });

  const { config: partnerConfig, isLoaded } = usePartnerConfig();

  useEffect(() => {
    if (!partnerConfig) {
      return;
    }

    const { maintenanceMode, enableIntercom } = partnerConfig;

    if (maintenanceMode) {
      setStatus({ maintenance: true });
    }

    if (!enableIntercom) {
      return;
    }

    intercom.boot(showDefaultIntercom);

    // no default intercom button on mobile, maintenance or when `showDefaultIntercom` is `false`
    intercom.showHideLauncherButton(
      !isDesktop || maintenanceMode || !showDefaultIntercom
    );
  }, [partnerConfig]);

  if (maintenance) {
    hidePageLoadIcon();
    return <MaintenanceView />;
  }

  if (error) {
    hidePageLoadIcon();
    return <UnavailableView />;
  }

  if (isLoaded) {
    hidePageLoadIcon();
    return <>{children}</>;
  }

  return <SpinnerOverlay />;
}

// by default we are showing an icon in the root page coming from the server for visual feedback
// hides it when react is ready to renders
function hidePageLoadIcon() {
  const element = document.getElementById('loading-indicator') as HTMLElement;

  if (element && element.parentElement) {
    element.parentElement.removeChild(element);
  }
}
