import React, { InputHTMLAttributes, ReactElement } from 'react';
import classnames from 'classnames';

import './styles.scss';

export type SwitchProps = InputHTMLAttributes<HTMLInputElement>;

export default function Switch(props: SwitchProps): ReactElement {
  const { className, checked, disabled, ...rest } = props;
  const activeClass = { 'switch-active': checked };
  const disabledClass = { 'switch-disabled': disabled };

  return (
    <label className={classnames('switch', className)}>
      <input
        {...rest}
        className="switch-input"
        type="checkbox"
        checked={checked}
        disabled={disabled}
        aria-checked={Boolean(checked)}
        alt={Boolean(checked).toString()}
      />
      <div className={classnames('switch-bg', activeClass, disabledClass)} />
      <div className={classnames('switch-toggle', activeClass, disabledClass)} />
    </label>
  );
}
