import polyfills from '../lib/polyfills';

polyfills(() => {
  // Support component names relative to this directory:
  const ReactRailsUJS = require('react_ujs');

  const componentRequireContext = require.context('pages', true);
  ReactRailsUJS.useContext(componentRequireContext);
  ReactRailsUJS.mountComponents();
});
