import React, { ReactElement } from 'react';
import classnames from 'classnames';
import MtIcon, { IIconComponentProps } from 'mt-web-icons/react/Icon/esm';

import './style.scss';

export interface IProps extends IIconComponentProps {
  icon: string;

  'data-testid'?: string;
}

export default function Icon(props: IProps): ReactElement {
  const { className } = props;

  return <MtIcon {...props} role="img" className={classnames('icon', className)} />;
}
