import React, {
  ReactElement,
  useEffect,
  lazy,
  useMemo,
  HTMLAttributes
} from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { inject } from 'mobx-react';
import classnames from 'classnames';

import reload from '../../lib/reload';
import { Data } from '../../models/data';
import intercom from '../../lib/Intercom';
import { usePartnerConfig } from '../../contexts/partnerConfig';

import './styles.scss';

const BrandConnection = lazy(() => import('../../components/BrandConnection'));
const AnonymousArea = lazy(() => import('../../components/AnonymousArea'));
const Headers = lazy(() => import('../../components/Headers'));
const TextButton = lazy(() => import('../../components/TextButton'));

function openIntercom() {
  intercom.open();
}

type Props = HTMLAttributes<HTMLDivElement>;

interface InjectedProps extends Props {
  dataModel: Data;
}

function MaintenanceView(props: Props): ReactElement {
  const {
    className,
    dataModel: { brandingInfo: { clientId, clientName = 'My Account' } },
    ...remainingProps
  } = props as InjectedProps;

  const { t } = useTranslation();
  const { config } = usePartnerConfig();

  useEffect(() => {
    reload.init();
  }, []);

  const intercomButton = useMemo(
    () => (
      <TextButton
        big={true}
        className="maintenance-view-button"
        text={t('MaintenanceView.contactSupportButton')}
        onPress={openIntercom}
      />
    ),
    []
  );

  return (
    <AnonymousArea
      {...remainingProps}
      className={classnames(className, 'maintenance-view')}
    >
      <Headers />
      <div className="maintenance-view-content">
        {!clientId && <div className="maintenance-view-image" />}
        {clientId && <BrandConnection clientId={clientId} />}
        <div className="maintenance-view-title">
          {t(`MaintenanceView.title`, { clientName: clientName || 'Moneytree' })}{' '}
        </div>
        <p className="maintenance-view-description">
          <Trans i18nKey="MaintenanceView.description">
            <br />
          </Trans>
        </p>
        {config?.enableIntercom && Boolean(intercom.hasClient()) && intercomButton}
      </div>
    </AnonymousArea>
  );
}

export default inject('dataModel')(MaintenanceView);
