import React, { ReactElement, PropsWithChildren, useState } from 'react';
import classnames from 'classnames';
import qs from 'qs';

import Button from '../../../../components/Button';
import Icon from '../../../../components/Icon';

import './styles.scss';

interface AccordionProps {
  id: string;
  title: string;
}

export default function Accordion(props: PropsWithChildren<AccordionProps>): ReactElement {
  const { id, title, children } = props;

  const { section } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const [show, setShow] = useState(section === id);

  function toggleAccordion() {
    setShow(!show);
  }

  return (
    <div className="accordion">
      <Button className="header" onPress={toggleAccordion}>
        <h4 className="title">{title}</h4>
        <Icon className={classnames({ 'point-down': show })} icon="chevron-right" />
      </Button>
      <div className={classnames('content', { show })}>
        {children}
      </div>
    </div>
  );
}
