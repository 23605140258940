import qs from 'qs';

const importantParamKeys = [
  'client_id',
  'cobrand_client_id',
  'continue',
  'state',
  'locale',
  'country',
  'configs'
];

// only get the query string that is important to the system
// we don't have to forward one time use query string when
// redirect to default page on error or page not found
export function importantParams() {
  const params = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });

  for (const param in params) {
    if (importantParamKeys.indexOf(param) === -1) {
      delete params[param];
    }
  }

  return params;
}

export function importantQueryString() {
  return qs.stringify(importantParams(), { addQueryPrefix: true });
}
