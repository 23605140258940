import React, { lazy, Suspense, useState, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { inject } from 'mobx-react';
import { History } from 'history';

import AnonymousArea from '../../components/AnonymousArea';
import Spinner from '../../components/Spinner';
import Headers from '../../components/Headers';
import TextButton from '../../components/TextButton';
import { Data } from '../../models/data';

import './styles.scss';

const LoginForm = lazy(() => import(/* webpackChunkName: "LoginForm" */ '../../components/forms/LoginForm'));
const SignUpForm = lazy(() => import(/* webpackChunkName: "SignUpForm" */ '../../components/forms/SignUpForm'));
const TrusteButton = lazy(() => import(/* webpackChunkName: "TrusteButton" */ './TrusteButton'));

export enum Form {
  SignUp = 'signup',
  Login = 'login'
}

function getOtherForm(form: Form): Form {
  return form === Form.SignUp ? Form.Login : Form.SignUp;
}

function renderAuthToggleButton(form: Form, history: History): ReactElement {
  const { t } = useTranslation();

  function changeForm() {
    history.push({
      ...history.location,
      pathname: getOtherForm(form)
    });
  }

  const isSignUp: boolean = form === Form.SignUp;

  return (
    <TextButton
      className="auth-view-swap-form-button"
      text={t(isSignUp ? 'LoginForm.label' : 'SignUpForm.label')}
      onPress={changeForm}
    />
  );
}

interface IProps {
  form: Form;
}

type InjectedProps = { dataModel: Data } & IProps;

function AuthView(props: IProps): ReactElement {
  const [password, setPassword] = useState('');
  const history = useHistory();

  const {
    form,
    dataModel: {
      country,
      customUi: { isOnboard, isSsoSession, showAuthToggle }
    }
  } = props as InjectedProps;

  const isSignUp: boolean = form === Form.SignUp;

  const formProps = {
    password,
    onChangePassword: (newPassword: string) => setPassword(newPassword)
  };

  return (
    <AnonymousArea className="auth-view">
      <Suspense fallback={<Spinner className="page-loading" />}>
        {isSignUp ? <SignUpForm {...formProps} /> : <LoginForm {...formProps} />}

        <div className="auth-view-truste-button-container">
          <TrusteButton country={country} />
        </div>
      </Suspense>
      <Headers>{!isOnboard && !isSsoSession && showAuthToggle && renderAuthToggleButton(form, history)}</Headers>
    </AnonymousArea>
  );
}

AuthView.defaultProps = {
  form: Form.Login
};

export default inject('dataModel')(AuthView);
