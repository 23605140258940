interface IPackagesInfo {
  name: string;
  url: string;
}

function isFunctionExistInWindow(name: string): boolean {
  // have to use `-` in filename do split from object followed by method
  const depths: any[] = [window, ...name.split('-')];
  const method = depths.reduce((obj: any, key: string) => obj[key]);

  // method supported
  return Boolean(method);
}

// to load cdn scripts via the DOM script tag
function load({ name, url }: IPackagesInfo, onLoad: () => void): void {
  if (isFunctionExistInWindow(name)) {
    return onLoad();
  }

  const script = document.createElement('script');
  script.async = false;
  script.src = url;
  script.onload = onLoad;

  if (document.head) {
    document.head.appendChild(script);
  }
}

const PROMISE_CDN = 'https://cdn.jsdelivr.net/npm/promise-polyfill@8/dist/polyfill.min.js';

const POLYFILLS = [
  {
    name: 'Set',
    importMethod: () => import(/* webpackChunkName: "core-js/es/set" */ 'core-js/es/set')
  },
  {
    name: 'Map',
    importMethod: () => import(/* webpackChunkName: "core-js/es/map" */ 'core-js/es/map')
  },
  {
    name: 'fetch', // for IE11
    importMethod: () => import(/* webpackChunkName: "whatwg-fetch" */ 'whatwg-fetch')
  },
  {
    name: 'Object-assign', // for old browser
    importMethod: () => import(/* webpackChunkName: "core-js/es/object/assign" */ 'core-js/es/object/assign')
  },
  {
    name: 'Number-isNaN', // for IE11
    importMethod: () => import(/* webpackChunkName: "core-js/es/number/is-nan" */ 'core-js/es/number/is-nan')
  },
  {
    name: 'Object-entries', // for IE11
    importMethod: () => import(/* webpackChunkName: "core-js/es/object/entries" */ 'core-js/es/object/entries')
  },
  {
    name: 'String-includes', // for IE11
    importMethod: () => import(/* webpackChunkName: "core-js/es/string/includes" */ 'core-js/es/string/includes')
  }
];

export default function(cb: () => void): void {
  // First load Promise, we need promise to async load other polyfills on demand.
  // Since we want to also lazy load Promise on demand (which requires a Promise to be async),
  // the only way is to load from external CDN (or we can load from local promise polyfill
  // package but don't know how to deduce the built path).
  load({ name: 'Promise', url: PROMISE_CDN }, async () => {
    for (const { name, importMethod } of POLYFILLS) {
      if (!isFunctionExistInWindow(name)) {
        await importMethod();
      }
    }

    cb();
  });
}
