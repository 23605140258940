import React, {
  ImgHTMLAttributes,
  PropsWithChildren,
  useCallback,
  useState
} from 'react';
import classnames from 'classnames';

import './style.scss';

export interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
}

// image element with on loaded animation
export default function Img(props: PropsWithChildren<Props>) {
  const { src, className, onLoad, ...rest } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  const onLoadMethod = useCallback((e) => {
    if (onLoad) {
      onLoad(e);
    }

    setIsLoaded(true);
  }, [onLoad]);

  return (
    <>
      {!isLoaded && (
        <span className={classnames('img', 'placeholder', className)} />
      )}
      <img
        {...rest}
        className={classnames('img', { visible: isLoaded }, className)}
        src={src}
        onLoad={onLoadMethod}
      />
    </>
  );
}
