import { RequestOptions } from 'mixpanel-browser';
import { Base } from './base';
import { LOGIN_METHOD_VALUE_ENUM } from './login';

function ssoOnboardingLanding(this: Base) {
  this.instance?.track('[ssoFastOnboarding]started', {
    ...this.default_properties
  });
}

function ssoOnboardingSignUpButtonClicked(this: Base, formClassName: string) {
  this.instance?.track_forms(
    `.${formClassName}`,
    '[ssoFastOnboarding]signupButton_clicked',
    { ...this.default_properties }
  );
}

function ssoOnboardingCancelButtonClicked(this: Base) {
  this.instance?.track(
    '[ssoFastOnboarding]cancelButton_clicked',
    { ...this.default_properties },
    { send_immediately: true } as RequestOptions
  );
}

function ssoConsentFormLanding(this: Base) {
  this.instance?.track('[ssoConsent]started', {
    ...this.default_properties
  });
}

function ssoConsentFormDecisionButtonClicked(this: Base, accepted = false) {
  this.instance?.track(
    '[ssoConsent]decided',
    {
      ...this.default_properties,
      accepted
    },
    { send_immediately: true } as RequestOptions
  );
}

function ssoLoginFormLanding(this: Base) {
  this.instance?.track('[ssoFastLogin]started', {
    ...this.default_properties
  });
}

function ssoLoginWithPasswordLinkClicked(this: Base) {
  this.instance?.track(
    '[ssoFastLogin]password_requested',
    { ...this.default_properties },
    { send_immediately: true } as RequestOptions
  );
}

// either from login with password button or email magic link buttons in various login forms
function ssoLoginButtonClicked(this: Base, login_method: LOGIN_METHOD_VALUE_ENUM) {
  this.instance?.track(
    '[ssoFastLogin]loginButton_clicked',
    {
      ...this.default_properties,
      method: login_method
    },
    { send_immediately: true } as RequestOptions
  );
}

export default {
  ssoOnboardingLanding,
  ssoOnboardingSignUpButtonClicked,
  ssoOnboardingCancelButtonClicked,
  ssoConsentFormLanding,
  ssoConsentFormDecisionButtonClicked,
  ssoLoginFormLanding,
  ssoLoginWithPasswordLinkClicked,
  ssoLoginButtonClicked
};
