export function isInIframe() {
  try {
    return self !== parent;
  } catch {
    // browser will prevent the child to access the parent and raise error during cross origin
    return true;
  }
}

export function postMessage<T extends object>(data: Omit<T, 'service'>) {
  // firefox doesn't support this, therefore we will have to skip it to avoid getting error
  if (!document.location.ancestorOrigins) {
    return;
  }

  parent.postMessage(
    { ...data, service: 'myaccount' },
    document.location.ancestorOrigins[0]
  );
}
