import React, { lazy, ReactElement, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import appPage from '../../../hoc/appPage';
import dataModel from '../../../models/data';
import appConfig from '../../../configs/app.json';
import { usePartnerConfig } from '../../../contexts/partnerConfig';
import { isInIframe } from 'lib/iframe';

const MobileContent = lazy(() => import('./MobileContent'));
const DesktopContent = lazy(() => import('./DesktopContent'));

function AuthenticatedPage(): ReactElement {
  const {
    canChangeLanguageOnMobile,
    brandingInfo: { clientId, isSsoClient },
    customUi: { allowIframeMode }
  } = dataModel;

  const { config } = usePartnerConfig();
  const isDesktop = useMediaQuery({
    minWidth: appConfig.desktopMinWidth,
    minHeight: appConfig.desktopMinHeight
  });

  const showLogoutButton = !(isSsoClient || (allowIframeMode && isInIframe()));

  return useMemo(
    () =>
      isDesktop ? (
        <DesktopContent
          enableIntercom={Boolean(config?.enableIntercom)}
          clientId={clientId}
          showLogoutButton={showLogoutButton}
        />
      ) : (
        <MobileContent canChangeLanguageOnMobile={canChangeLanguageOnMobile} />
      ),
    [isDesktop]
  );
}

export default appPage(AuthenticatedPage);
