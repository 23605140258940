import qs from 'qs';

export function samlSubjectIdParamCleanUp() {
  const search = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  if (!('saml_subject_id' in search)) {
    return;
  }

  delete search.saml_subject_id;

  window.history.replaceState(
    null,
    '',
    qs.stringify(search, { addQueryPrefix: true })
  );
}
