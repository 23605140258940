import { useState, useEffect } from 'react';

import dataModel from '../models/data';
import { ClientInfo } from '../interfaces/ClientInfo';
import { useHealthStatus } from '../contexts/healthStatus';
import { defaultConfigs } from '../contexts/partnerConfig';

interface IGetParams {
  clientId: string;
  country: string;
}

interface UseClientInfoState<T> {
  loading: boolean;
  config?: T;
  error?: string;
}

const defaultClientInfo = {
  clientId: '',
  name: '',
  isMtClient: false,
  partnerConfigs: defaultConfigs
};

export function useClientInfo(params: IGetParams): UseClientInfoState<ClientInfo> {
  const { clientId: clientIdParams, country } = params;
  const clientId = clientIdParams || 'global';

  const [state, setState] = useState<UseClientInfoState<ClientInfo>>({
    loading: false,
    config: undefined,
    error: undefined
  });

  const { setStatus } = useHealthStatus();

  useEffect(() => {
    if (!country) {
      setState({
        config: defaultClientInfo as ClientInfo,
        loading: false,
        error: undefined
      });
      return;
    }

    const { omoikaneUrl } = dataModel;

    const url = `${omoikaneUrl.replace('{country}', country)}/api/v1/client-app-info/${clientId}.json`;

    fetch(url, {
      method: 'GET',
      headers: {
        'mt-app-name': 'my-account'
      }
    })
      .then((response) => {
        if (response.status === 503) {
          setStatus({ maintenance: true });
          return;
        }

        if (response.status !== 200) {
          setStatus({ error: true });
          return;
        }

        return response.json().then((clientInfo) => {
          setState({
            config: clientInfo,
            loading: false,
            error: undefined
          });
        });
      })
      .catch(
        (error) => {
          setState({ loading: false, config: undefined, error: error.message });
          setStatus({ error: true });
        }
      );
  }, [clientId, country]);

  return state;
}


interface IUpdateParams<T> {
  clientId: string;
  country: string;
  configKey: string;
  configValue: T;
}

export async function updatePartnerConfig<T>(params: IUpdateParams<T>): Promise<void> {
  const { clientId, country, configKey, configValue } = params;

  const response = await fetch('partner-configuration', {
    method: 'PUT',
    headers: {
      'mt-app-name': 'my-account',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      country,
      client_id: clientId,
      config_key: configKey,
      config_value: configValue
    })
  });

  if (response.status !== 200) {
    throw new Error('service:partnerConfig:update');
  }
}
