import React, {
  SelectHTMLAttributes,
  useCallback,
  ReactElement,
  ReactNode,
  useState,
  ChangeEvent,
  useMemo,
  useEffect
} from 'react';
import classnames from 'classnames';

import Icon from '../Icon';

import './styles.scss';

export interface DropdownEntry {
  label: string;
  value: string;
  iconElement?: ReactNode;
}

export interface DropdownProps extends SelectHTMLAttributes<HTMLSelectElement> {
  title?: string;
  list: DropdownEntry[];
}

export default function Dropdown(props: DropdownProps): ReactElement {
  const { list, title, className, onChange, defaultValue, value: dropdownValue, ...rest } = props;

  const [icon, setIcon] = useState<ReactNode>(null);

  useEffect(() => {
    for (const { value, iconElement } of list) {
      if (value === (dropdownValue || defaultValue)) {
        setIcon(iconElement);
      }
    }
  }, [defaultValue, dropdownValue]);

  const renderOptions = useMemo(
    () =>
      list.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      )),
    [list]
  );

  const onSelectChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const { selectedIndex } = e.currentTarget;
      const { iconElement } = list[selectedIndex];

      if (iconElement) {
        setIcon(iconElement);
      }

      if (onChange) {
        onChange(e);
      }
    },
    [onChange]
  );

  return (
    <label className={classnames('dropdown', className)}>
      {title && <div className="dropdown-title">{title}</div>}
      <div className="dropdown-container">
        {icon && <div className="dropdown-icon">{icon}</div>}
        <select
          {...rest}
          defaultValue={defaultValue}
          value={dropdownValue}
          className={classnames('dropdown-select', { 'has-icon': icon })}
          onChange={onSelectChange}
        >
          {renderOptions}
        </select>
        <Icon className="dropdown-down-arrow" icon="nub-down" />
      </div>
    </label>
  );
}
