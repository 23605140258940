import React, { ReactElement, useState, ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import ToggleSetting from '../../../../components/ToggleSetting';
import { UniversalVaultConfig, ClientInfo } from '../../../../interfaces/ClientInfo';
import Accordion from '../Accordion';
import { usePartnerConfigContext } from '../AppInfoContext';
import update from '../update';

import './styles.scss';

const defaultSettings = [
  {
    title: 'Export transactions',
    name: 'enable_export_transactions',
    defaultValue: false
  },
  {
    title: 'Enable custom account',
    name: 'enable_custom_account',
    defaultValue: false
  },
  {
    title: 'Show powered by Moneytree label',
    name: 'show_powered_by_moneytree_label',
    defaultValue: false
  },
  {
    title: 'Hide onboarding card',
    name: 'hide_onboarding_card',
    defaultValue: false
  }
];

export default function UniversalVaultPartnerConfig(): ReactElement {
  const { clientId, country, clientInfo } = usePartnerConfigContext();

  const {
    partnerConfigs: { universalVault }
  } = clientInfo as ClientInfo;

  const [disabledList, setDisabledList] = useState<string[]>([]);
  const [isChanged, setIsChanged] = useState(false);
  const [universalVaultConfig, setUniversalVaultConfig] = useState(universalVault || ({} as UniversalVaultConfig));

  useEffect(() => {
    if (!isChanged) {
      return;
    }

    update<UniversalVaultConfig>(clientId, country, 'universal_vault', universalVaultConfig)
      .then((status: boolean) => {
        setIsChanged(false);

        if (!status) {
          setUniversalVaultConfig(universalVault || ({} as UniversalVaultConfig));
        }

        disabledList.shift();
        setDisabledList([...disabledList]);
      })
      .catch();
  }, [universalVaultConfig]);

  const onToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked, name } = e.currentTarget;

      setUniversalVaultConfig({
        ...universalVaultConfig,
        [name]: checked
      });

      disabledList.push(name);
      setDisabledList(disabledList);

      setIsChanged(true);
    },
    [universalVaultConfig]
  );

  const content = useMemo(
    () =>
      defaultSettings.map(({ defaultValue, name, title }) => (
        <ToggleSetting
          key={name}
          name={name}
          title={title}
          checked={universalVaultConfig[name as keyof UniversalVaultConfig] || defaultValue}
          onChange={onToggle}
          disabled={disabledList.indexOf(name) !== -1}
        />
      )),
    [universalVaultConfig, disabledList]
  );

  return (
    <Accordion title="Universal Vault" id="universal-vault">
      <div className="universal-vault-config">{content}</div>
    </Accordion>
  );
}
