import React from 'react';
import classnames from 'classnames';

import Icon from '../Icon';

import './styles.scss';

interface IProps {
  className?: string;
  title?: string;
  onChangeText?: (value: string, isValid: boolean) => void;
  validation?: (value: string) => boolean;
  message?: string;
  multiLine?: boolean;

  'data-testid'?: string;
}

export interface IInputProps extends IProps, React.InputHTMLAttributes<HTMLInputElement> {}
interface ITextAreaProps extends IProps, React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

interface IState {
  isValid: boolean;
  isTyping: boolean;
}

export default class TextInput extends React.PureComponent<IInputProps | ITextAreaProps, IState> {
  public static defaultProps: IInputProps | ITextAreaProps = {
    className: '',
    title: undefined,
    message: undefined,
    multiLine: false,
    onChangeText: () => undefined
  };
  private readonly inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: IInputProps | ITextAreaProps) {
    super(props);

    const { defaultValue = '' } = props;
    const { value = defaultValue } = props;
    const isValid = Boolean(props.validation && props.validation(value.toString()));

    this.state = {
      isValid,
      isTyping: false
    };

    if (value && this.props.onChangeText) {
      this.props.onChangeText(value.toString(), isValid);
    }

    this.inputRef = React.createRef();
  }

  public render() {
    const { value, style, className, title, message, 'data-testid': testId } = this.props;
    const { isValid, isTyping } = this.state;
    // do not show validation state while typing
    const showMessage = Boolean(message && !isValid && value && !isTyping);

    return (
      <div className={classnames('text-input', className)} style={style} data-testid={testId}>
        {title && <div className="text-input-title">{title}</div>}
        {this.renderInput()}
        {isValid && <Icon className="text-input-tick" icon="check" />}
        {showMessage && <div className="text-input-message">{message}</div>}
      </div>
    );
  }

  public componentDidUpdate(prevProps: IInputProps | ITextAreaProps) {
    const { validation, value } = this.props;

    if (value === prevProps.value) {
      return;
    }

    this.setState({
      isValid: validation && value ? validation(value.toString()) : Boolean(value)
    });
  }

  public componentDidMount() {
    this.setState({ isTyping: false });
  }

  public validate() {
    const { onChangeText, validation, value = '' } = this.props;

    const isValid = Boolean(validation && validation(value.toString()));

    if (onChangeText) {
      onChangeText(value.toString(), isValid);
    }
  }

  public focus() {
    this.inputRef.current?.focus();
  }

  private onBlur = () => {
    const { validation, value } = this.props;

    this.setState({
      isValid: validation && value ? validation(value.toString()) : Boolean(value),
      isTyping: false
    });
  }

  private onFocus = () =>
    this.setState({
      isValid: false,
      isTyping: true
    })

  private onTextChangeValidate = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: string = e.target.value;

    const { maxLength, onChangeText, validation } = this.props;

    // cap the value length to the max length
    if (maxLength && value.length > maxLength) {
      value = value.substr(0, maxLength);
    }

    const isValid = Boolean(validation && validation(value));

    if (onChangeText) {
      onChangeText(value, isValid);
    }
  }

  private renderInput() {
    const { multiLine, value } = this.props;
    const { isValid, isTyping } = this.state;

    // props for input element
    const props = {
      ...this.props,
      value,
      className: classnames(
        'text-input-element',
        multiLine ? 'text-input-element-textarea' : 'text-input-element-input',
        {
          'text-input-fill': Boolean(isValid && value),
          'text-input-warning': Boolean(!isValid && value && !isTyping)
        }
      ),
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      autoComplete: 'off',
      role: 'input'
    };
    delete props.style;
    delete props.onChangeText;
    delete props.validation;
    delete props.message;
    delete props.multiLine;
    delete props['data-testid'];

    return multiLine ? (
      <textarea {...(props as ITextAreaProps)} />
    ) : (
      <input ref={this.inputRef} {...(props as IInputProps)} onChange={this.onTextChangeValidate} />
    );
  }
}
