import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';

import './styles.scss';

export default function AnonymousArea(props: HTMLAttributes<HTMLDivElement>) {
  const { children, className, ...remainingProps } = props;

  return <div {...remainingProps} className={classnames('anonymous-area', className)}>{children}</div>;
}
