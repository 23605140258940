import React, { ReactElement, PropsWithChildren, useMemo } from 'react';
import classnames from 'classnames';

import BackButton from '../../BackButton';
import MoneytreeIcon from '../../MoneytreeIcon';
import SproutLogo from '../../SproutLogo';

import './styles.scss';

export interface IProps {
  className?: string;
  backButton?: boolean;
  title?: string;
}

export default function Header(props: PropsWithChildren<IProps>): ReactElement {
  const { children, className, backButton = false, title = '' } = props;

  const logo = useMemo(
    () =>
      title ? (
        <MoneytreeIcon className="moneytree-icon" />
      ) : (
        <SproutLogo className="moneytree-logo" />
      ),
    [title]
  );

  return (
    <div className={classnames('header', className)}>
      <div className="left-content">
        {backButton && <BackButton />}
        {!backButton && logo}
        {title && <span className="title">{title}</span>}
      </div>
      {children}
    </div>
  );
}
