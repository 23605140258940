import { action, observable, computed } from 'mobx';
import qs from 'qs';

import LinkedApplications from '../interfaces/LinkedApplications';
import { qsConfigs, updateQueryString } from '../lib/helpers';

interface ICobrandClientApp {
  name: string;
  uid: string;
  isMTClient: boolean;
  isSsoClient: boolean;
}

export interface IClientApp extends ICobrandClientApp {
  scopes: string[];
  isMTLinkMobile?: boolean;
}

interface IOAuth {
  clientId: string;
  redirectUri: string;
  responseType: string;
  scope?: string;
  state?: string;
  codeChallenge?: string;
  codeChallengeMethod?: string;
}

export class Data {
  [index: string]: any;

  public env = 'development';
  public supportedCountries = ['JP'];
  public omoikaneUrl = '';
  public formAuthenticityToken = '';
  public uid = '';
  public emailConfirmed = false;
  public emailConfirmationSentAt = '';
  public currency = '';
  public country = '';
  public locale = '';
  public magicLinkTo = '';
  public backToUrl = '';
  public intercomAppID = '';
  public intercomHash = '';
  public createdAt = '';
  public assetsPath = '';

  public analytics = {
    token: '',
    debug: false
  };

  @observable public email = '';
  @observable public unconfirmedEmail = '';

  public linkedApplications: LinkedApplications[] = [];
  public clientApp: IClientApp = {
    name: '',
    uid: '',
    scopes: [],
    isMTClient: false,
    isMTLinkMobile: false,
    isSsoClient: false
  };
  public cobrandClientApp: ICobrandClientApp = {
    name: '',
    uid: '',
    isMTClient: false,
    isSsoClient: false
  };

  public oAuth: IOAuth = {
    clientId: '',
    redirectUri: '',
    responseType: '',
    scope: undefined,
    state: undefined,
    codeChallenge: undefined,
    codeChallengeMethod: undefined
  };

  // value from server to force UI to display differently based on the server state
  public customUi = {
    isOnboard: false,
    isSsoSession: false,
    hasSsoReference: false,
    showAuthToggle: true,
    // when onboardGeneratedPassword is true, it means the first account password was auto generated
    // meaning guest do not know it therefore not required to input it during first password update
    onboardGeneratedPassword: false,
    allowIframeMode: false
  };

  @computed
  get brandingInfo(): { clientId: string; clientName: string, isSsoClient: boolean } {
    return {
      clientId: this.cobrandClientApp.uid || this.clientApp.uid,
      clientName: this.cobrandClientApp.name || this.clientApp.name,
      isSsoClient: this.cobrandClientApp.isSsoClient || this.clientApp.isSsoClient
    };
  }

  get isExternalClient(): boolean {
    // condition that considered as external client (with brand display)
    // pattern: client_id + cobrand_client_id
    // 1. mt-app + non-mt-app
    // 2. non mt-app + non-mt-app
    // 3. non-mt-app + none
    // 4. none + non-mt-app

    // Edge: non-mt-app + mt-app -> treat it as internal

    const { isMTClient, uid: clientId } = this.clientApp;
    const {
      isMTClient: cobrandIsMtClient,
      uid: cobrandClientId
    } = this.cobrandClientApp;

    if (!clientId && !cobrandClientId) {
      return false;
    }

    const condition1 =
      clientId && cobrandClientId && isMTClient && !cobrandIsMtClient;
    const condition2 =
      clientId && cobrandClientId && !isMTClient && !cobrandIsMtClient;
    const condition3 = clientId && !cobrandClientId && !isMTClient;
    const condition4 = !clientId && cobrandClientId && !cobrandIsMtClient;

    return Boolean(condition1 || condition2 || condition3 || condition4);
  }

  // mt-link-mobile (sdk) should not be able to change language, always follow the device language
  get canChangeLanguageOnMobile(): boolean {
    // condition to disabled change language
    // 1. mt-link-mobile + non-mt-app

    const haveClients: boolean =
      Boolean(this.clientApp.uid) && Boolean(this.cobrandClientApp.uid);
    return !(haveClients &&
      this.clientApp.isMTLinkMobile &&
      !this.cobrandClientApp.isMTClient);
  }

  // update local data model with data from the server
  @action public setup(data: Data) {
    function recursiveSetKey(local: Data, obj: Data) {
      const keys = Object.keys(obj);

      for (const key of keys) {
        const value = obj[key];

        // skip same value, null
        if (local[key] === value || value === null) {
          continue;
        }

        // set boolean value
        if (value === 'false' || value === 'true') {
          local[key] = value === 'false' ? false : true;
          continue;
        }

        // update value only when the type is same and if is null, not an object or is an array
        if (typeof value !== 'object' || Array.isArray(value)) {
          local[key] = value;
          continue;
        }

        // recursively perform data set if value is an object
        recursiveSetKey(local[key], value);
      }
    }

    recursiveSetKey(this, data);
  }

  @action public updateEmail(newEmail: string, forceUpdateQueryString = false) {
    this.email = newEmail;

    // update email value in configs query params
    const configs = qsConfigs(true);

    if (!forceUpdateQueryString && configs.email === undefined) {
      return;
    }

    configs.email = newEmail;

    updateQueryString('configs', qs.stringify(configs));
  }

  @action public updateUnconfirmedEmail(email: string, sentAt: string) {
    this.unconfirmedEmail = email;
    this.emailConfirmationSentAt = sentAt;
  }
}

export default new Data() as Data & { [key: string]: any };
