import React, { ReactElement, lazy } from 'react';

import adminPage from '../../../hoc/adminPage';

const AdminLoginView = lazy(() => import(/* webpackChunkName: "AdminLoginView" */ '../../../views/AdminLoginView'));

function AdminAnonymousPage(): ReactElement {
  return (
    <div id="AdminAnonymousPage" className="admin-anonymous-page">
      <AdminLoginView />
    </div>
  );
}

export default adminPage(AdminAnonymousPage);
