import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';

import { qsConfigs } from '../../../lib/helpers';
import { Data } from '../../../models/data';
import appConfig from '../../../configs/app.json';
import analytics from '../../../services/analytics';

import Button from '../../Button';
import Icon from '../../Icon';

import './styles.scss';

interface Props {
  url: string;
}

interface InjectedProps extends Props {
  dataModel: Data;
}

function BackButtonBar(props: Props): ReactElement {
  const { t } = useTranslation();
  const {
    url,
    dataModel: {
      brandingInfo: { clientName },
      env
    }
  } = props as InjectedProps;

  function onPress(): void {
    const { sdkPlatform, isNewTab } = qsConfigs();

    // if there is `isNewTab` in configs parameter (only way to get it is by entering it manually
    // or assign by new js sdk >= v2) and it is not from native SDK,
    // we will close the window/tab when clicking on the back to button bar
    const isNativeSdk =
      ['android', 'ios'].indexOf(sdkPlatform as string) === -1;

    if (isNativeSdk && isNewTab) {
      analytics.backToButtonClicked('window.close');

      window.close();

      // this will indicate if `window.close()` succeed, continue with the flow if it failed
      if (window.closed) {
        return;
      }
    }

    if (url) {
      analytics.backToButtonClicked(url);
      window.location.href = url;
    }
  }

  const returnToVault =
    (env === 'production' &&
      url.startsWith('https://vault.getmoneytree.com')) ||
    (env === 'staging' &&
      url.startsWith('https://vault-staging.getmoneytree.com'));

  const title = returnToVault
    ? t('BackButtonBar.titleWithoutClientName')
    : t('BackButtonBar.titleWithClientName', { name: clientName });

  function mediaQueryRender(isDesktop: boolean): ReactElement {
    const mobileClass = {
      'back-button-bar-content-mobile': !isDesktop
    };

    return (
      <div className={classnames('back-button-bar-content', mobileClass)}>
        <Icon className="back-button-bar-arrow" icon="back" />
        <div className="back-button-bar-text">{title}</div>
      </div>
    );
  }

  return (
    <Button className="back-button-bar" onPress={onPress}>
      <MediaQuery
        minWidth={appConfig.desktopMinWidth}
        minHeight={appConfig.desktopMinHeight}
      >
        {mediaQueryRender}
      </MediaQuery>
    </Button>
  );
}

export default inject('dataModel')(BackButtonBar);
