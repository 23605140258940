import { RequestOptions } from 'mixpanel-browser';

import shortClientId from '../../lib/shortClientId';
import { Base } from './base';

function authorizedApplicationsVisited(this: Base) {
  this.instance?.track('[myAccount]authApplications_visited', {
    ...this.default_properties
  });
}

function authorizedApplicationsRevoked(this: Base, revokedAppUID: string, revokedScopes: string[]) {
  this.instance?.track(
    '[myAccount]authApplication_revoked',
    {
      ...this.default_properties,
      revokedAppUID: shortClientId(revokedAppUID),
      revokedScopes
    },
    { send_immediately: true } as RequestOptions
  );
}

export default {
  authorizedApplicationsVisited,
  authorizedApplicationsRevoked
};
