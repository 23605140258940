import React, {
  ReactElement,
  PropsWithChildren,
  lazy,
  useMemo,
  useCallback
} from 'react';
import { useTranslation, Trans } from 'react-i18next';

import dataModel from '../../models/data';
import intercom from '../../lib/Intercom';
import backToWithAction from '../../lib/back-to-with-action';
import { usePartnerConfig } from '../../contexts/partnerConfig';

import './styles.scss';

const AnonymousArea = lazy(
  () =>
    import(
      /* webpackChunkName: "AnonymousArea" */ '../../components/AnonymousArea'
    )
);
const Headers = lazy(
  () => import(/* webpackChunkName: "Headers" */ '../../components/Headers')
);
const TextButton = lazy(
  () => import(/* webpackChunkName: "Headers" */ '../../components/TextButton')
);

function openIntercom() {
  intercom.open();
}
interface ErrorViewProps {
  errorCode?: number | string;
  requestID?: string;
}

export default function ErrorView(
  props: PropsWithChildren<ErrorViewProps>
): ReactElement {
  const { errorCode = 500, requestID: errorRef } = props;

  const {
    backToUrl,
    brandingInfo: { clientName }
  } = dataModel;

  const { t } = useTranslation();
  const { config } = usePartnerConfig();

  const goBackToAppAction = useCallback(() => backToWithAction('close'), []);

  const goBackToApp = useMemo(
    () => (
      <TextButton
        big={true}
        className="error-view-button error-view-contact-support-button"
        text={t('ErrorView.goBackToAppButton', { clientName })}
        onPress={goBackToAppAction}
      />
    ),
    []
  );

  const intercomButton = useMemo(
    () => (
      <>
        <div className="error-view-description error-view-contact-support-description">
          {t(`ErrorView.contactSupportDescription`)}
        </div>
        <TextButton
          big={true}
          className="error-view-button"
          text={t('ErrorView.contactSupportButton')}
          onPress={openIntercom}
        />
      </>
    ),
    []
  );

  return (
    <AnonymousArea className="error-view">
      <Headers />
      <div className="error-view-content">
        <div className="error-view-image" />
        <div className="error-view-title">
          {t(`ErrorView.${errorCode}.title`)}{' '}
        </div>
        <div className="error-view-description">
          <Trans i18nKey={`ErrorView.${errorCode}.description`}>
            <div />
          </Trans>
        </div>
        {errorRef && (
          <div className="error-view-description">
            {t('ErrorView.errorReference', { requestID: errorRef })}
          </div>
        )}
        {config?.enableIntercom &&
          Boolean(intercom.hasClient()) &&
          intercomButton}
        {backToUrl && goBackToApp}
      </div>
    </AnonymousArea>
  );
}
