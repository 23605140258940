import { RequestOptions } from 'mixpanel-browser';
import { Base } from './base';

function loginFormLanding(this: Base) {
  this.instance?.track('[login]started', {
    ...this.default_properties
  });
}

function loginFormLoginButtonClicked(this: Base, login_method: LOGIN_METHOD_VALUE_ENUM) {
  this.instance?.track(
    '[login]loginButton_clicked',
    {
      ...this.default_properties,
      method: login_method
    },
    { send_immediately: true } as RequestOptions
  );
}

function loginFormLoginWithPasswordLinkClicked(this: Base) {
  this.instance?.track(
    '[login]password_requested',
    { ...this.default_properties },
    { send_immediately: true } as RequestOptions
  );
}

export default {
  loginFormLanding,
  loginFormLoginButtonClicked,
  loginFormLoginWithPasswordLinkClicked
};

export const LOGIN_METHOD = {
  LOGIN_LINK: 'login_link_page',
  LOGIN_LINK_VIA_PASSWORD: 'login_link_via_password_page',
  PASSWORD: 'password_page'
};

export type LOGIN_METHOD_VALUE_ENUM = (typeof LOGIN_METHOD)[keyof (typeof LOGIN_METHOD)];
