import { Base } from './base';
import loginMethods from './login';
import oauthConsentMethods from './oauthConsent';
import passwordlessOnboardMethods from './passwordlessOnboard';
import signupMethods from './signup';
import ssoMethods from './sso';
import backToMethods from './backTo';
import authorizedApplicationsMethods from './authorizedApplications';
import deleteAccountMethods from './deleteAccount';

const extendsMethods = {
  ...loginMethods,
  ...oauthConsentMethods,
  ...passwordlessOnboardMethods,
  ...signupMethods,
  ...ssoMethods,
  ...backToMethods,
  ...authorizedApplicationsMethods,
  ...deleteAccountMethods
} as const;

type ExtendMethods = typeof extendsMethods;

export type Analytics = Base & ExtendMethods;

const analytics = new Base() as Analytics;

Object.keys(extendsMethods).forEach((methodName) => {
  Object.defineProperty(Base.prototype, methodName, {
    value: extendsMethods[methodName as keyof ExtendMethods]
  });
});

export default analytics;
