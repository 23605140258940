import { useState, useEffect } from 'react';

export function useDebounce<T>(value: T, callback: (returned_value: T | string) => void, ms = 300) {
  const [timeoutRef, setTimeoutRef] = useState(0);

  useEffect(() => {
    window.clearTimeout(timeoutRef);

    setTimeoutRef(window.setTimeout(() => callback(value), ms));

    return () => window.clearTimeout(timeoutRef);
  }, [value]);
}
