import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Dropdown, { DropdownProps, DropdownEntry } from '../Dropdown';
import localeModel from '../../models/locale';
import Img from '../Img';

import './styles.scss';

type CountryDropdownProps = Omit<Omit<DropdownProps, 'title'>, 'list'> & {
  withCurrency?: boolean;
};

export default function CountryDropdown(props: CountryDropdownProps): ReactElement {
  const { withCurrency = true, className, ...rest } = props;
  const { t } = useTranslation();

  const countryList: DropdownEntry[] = useMemo(
    () =>
      localeModel.supportedCountries.map((countryCode: string) => {
        const label = `${t(`countries.${countryCode}`)} ${withCurrency ? `/ ${t(`currencies.${countryCode}`)}` : ''}`;

        return {
          label,
          value: countryCode,
          iconElement: (
            <Img
              className="country-icon"
              src={require(`assets/images/icons/countries/${countryCode.toLowerCase()}.svg`)}
            />
          )
        };
      }),
    [withCurrency]
  );

  return (
    <Dropdown
      {...rest}
      className={classnames('country-dropdown', className)}
      title={t(`CountryDropdown.title${withCurrency ? '-with-currency' : ''}`)}
      list={countryList}
    />
  );
}
