import React, { useEffect, createContext, ReactElement, HTMLAttributes, useState, useMemo, useContext } from 'react';
import qs from 'qs';

import { useClientInfo } from '../../../services/client-info';
import { ClientInfo } from '../../../interfaces/ClientInfo';
import toastsModel from '../../../models/toasts';
import { Preset } from '../../../components/Toast';

interface AppInfoContext {
  clientId: string;
  country: string;
  loading: boolean;
  clientInfo?: ClientInfo;
  error?: string;
  updateClientId: (clientId: string) => void;
  updateCountry: (country: string) => void;
}

const AppInfoContext = createContext<AppInfoContext>({
  clientId: '',
  country: '',
  clientInfo: undefined,
  loading: false,
  error: undefined,
  updateClientId: () => undefined,
  updateCountry: () => undefined
});

export function usePartnerConfigContext() {
  return useContext(AppInfoContext);
}

export default function AppContextProvider(props: HTMLAttributes<HTMLElement>): ReactElement {
  const { children } = props;

  const [clientId, setClientId] = useState('');
  const [country, setCountry] = useState('');

  // get value from query parameters
  useEffect(() => {
    const { client_id: queryClientId, country: queryCountry } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setClientId((Array.isArray(queryClientId) ? queryClientId[queryClientId.length - 1] : queryClientId) as string);
    setCountry((Array.isArray(queryCountry) ? queryCountry[queryCountry.length - 1] : queryCountry) as string);
  }, []);

  const { loading, config, error } = useClientInfo({ clientId, country });

  useEffect(() => {
    // show toasts after finish loading
    if (loading) {
      return;
    }

    if (error) {
      toastsModel.add({
        text: `Failed to load partner configuration for client id: ${clientId}`,
        preset: Preset.Danger
      });

      return;
    }

    if (config) {
      toastsModel.add({
        text: `Successfully loaded partner configuration for client id: ${clientId}`
      });
    }
  }, [loading, error, config]);

  const appContext = useMemo(
    () => ({
      clientId,
      country,
      clientInfo: config,
      loading,
      error,
      updateClientId: (newClientId: string) => setClientId(newClientId),
      updateCountry: (newCountry: string) => setCountry(newCountry)
    }),
    [clientId, country, config, loading, error]
  );

  return <AppInfoContext.Provider value={appContext}>{children}</AppInfoContext.Provider>;
}
