import React, { ReactElement, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { importantQueryString } from '../../../../lib/importantParams';

const AuthorizedApplicationsView = lazy(() =>
  import(/* webpackChunkName: "AuthorizedApplicationsView" */ '../../../../views/AuthorizedApplicationsView')
);
const ChangeLanguageView = lazy(() =>
  import(/* webpackChunkName: "ChangeLanguageView" */ '../../../../views/ChangeLanguageView')
);
const DeleteAccountView = lazy(() =>
  import(/* webpackChunkName: "DeleteAccountView" */ '../../../../views/DeleteAccountView')
);
const EmailPreferencesView = lazy(() =>
  import(/* webpackChunkName: "EmailPreferencesView" */ '../../../../views/EmailPreferencesView')
);
const SettingsView = lazy(() => import(/* webpackChunkName: "SettingsView" */ '../../../../views/SettingsView'));
const UpdateEmailView = lazy(() =>
  import(/* webpackChunkName: "UpdateEmailView" */ '../../../../views/UpdateEmailView')
);
const UpdatePasswordView = lazy(() =>
  import(/* webpackChunkName: "UpdatePasswordView" */ '../../../../views/UpdatePasswordView')
);

interface Props {
  canChangeLanguageOnMobile: boolean;
}

export default function MobileContent(props: Props): ReactElement {
  const { canChangeLanguageOnMobile } = props;

  // mt-link-mobile (sdk) should not be able to change language, always follow the device language
  const renderChangeLanguageRoute: JSX.Element | boolean = canChangeLanguageOnMobile && (
    <Route exact path="/settings/change-language" component={ChangeLanguageView} />
  );

  return (
    <Switch>
      <Route exact path="/settings" component={SettingsView} />
      <Route exact path="/settings/update-email" component={UpdateEmailView} />
      <Route exact path="/settings/update-password" component={UpdatePasswordView} />
      {renderChangeLanguageRoute}
      <Route exact path="/settings/contact-support" component={UpdateEmailView} />
      <Route exact path="/settings/email-preferences" component={EmailPreferencesView} />
      <Route exact path="/settings/authorized-applications" component={AuthorizedApplicationsView} />
      <Route exact path="/settings/delete-account" component={DeleteAccountView} />
      <Redirect to={`/settings${importantQueryString()}`} />
    </Switch>
  );
}
