import React, { lazy, ReactElement } from 'react';

import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import appPage from '../../../hoc/appPage';
import { Form } from '../../../views/AuthView';
import { importantQueryString } from '../../../lib/importantParams';

const AuthView = lazy(() => import('../../../views/AuthView'));
const CantLoginView = lazy(() => import('../../../views/CantLoginView'));
const CheckEmailView = lazy(() => import('../../../views/CheckEmailView'));
const NewPasswordView = lazy(() => import('../../../views/NewPasswordView'));
const ReconfirmAccountView = lazy(() => import('../../../views/ReconfirmAccountView'));
const ResetPasswordView = lazy(() => import('../../../views/ResetPasswordView'));
const UnlockAccountView = lazy(() => import('../../../views/UnlockAccountView'));
const WrongDeviceView = lazy(() => import('../../../views/WrongDeviceView'));

function renderLoginForm(props: RouteComponentProps<null>): ReactElement {
  return <AuthView {...props} form={Form.Login} />;
}

function renderSignUpForm(props: RouteComponentProps<null>): ReactElement {
  return <AuthView {...props} form={Form.SignUp} />;
}

function AnonymousPage(): ReactElement {
  return (
    <Switch>
      <Route exact={true} path={`/${Form.Login}`} render={renderLoginForm} />
      <Route exact={true} path={`/${Form.SignUp}`} render={renderSignUpForm} />
      <Route exact={true} path="/login-help" component={CantLoginView} />
      <Route exact={true} path="/new-password" component={NewPasswordView} />
      <Route exact={true} path="/forgot-password" component={ResetPasswordView} />
      <Route exact={true} path="/resend-confirmation" component={ReconfirmAccountView} />
      <Route exact={true} path="/unlock-account" component={UnlockAccountView} />
      <Route exact={true} path="/check-email-magic-link" component={CheckEmailView} />
      <Route exact={true} path="/wrong-device" component={WrongDeviceView} />
      <Redirect to={`/login${importantQueryString()}`} />
    </Switch>
  );
}

export default appPage(AnonymousPage);
