import React, { ReactElement, useEffect, useState, Suspense, FC, lazy } from 'react';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { Switch } from 'react-router-dom';

import toastsModel from '../../models/toasts';
import dataModel from '../../models/data';
import localeModel from '../../models/locale';
import spinnerOverlayModel from '../../models/spinnerOverlay';

import PageLoading from '../../components/PageLoading';
import SpinnerOverlay from '../../components/SpinnerOverlay';

import './styles.scss';

const ToastManager = lazy(() => import(/* webpackChunkName: "ToastManager" */ '../../components/ToastManager'));

interface AdminPageProps {
  payload: string;
}

export default function adminPage(component: FC) {
  return function AdminPage(props: AdminPageProps): ReactElement {
    const { payload } = props;
    const [initReady, setInitReady] = useState(false);

    useEffect(() => {
      const { toasts, toastsCode, data } = JSON.parse(decodeURIComponent(atob(payload)));

      dataModel.setup(data);

      const init = async () => {
        await localeModel.init();

        // toasts
        toastsModel.multiAdd(toasts);
        toastsModel.multiAddCode(toastsCode);

        setInitReady(true);
      };

      init().catch(() => {
        console.error('Failed to initialize Guest Service admin');
      });
    }, []);

    if (!initReady) {
      return <PageLoading />;
    }

    return (
      <Provider dataModel={dataModel} spinnerOverlayModel={spinnerOverlayModel} localeModel={localeModel}>
        <BrowserRouter>
          <Switch>
            <Suspense fallback={<PageLoading />}>
              <div className="admin-page">
                {React.createElement(component)}
                <SpinnerOverlay />
              </div>
              <ToastManager />
            </Suspense>
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  };
}
