import React from 'react';
import classnames from 'classnames';

import './styles.scss';

export interface IProps {
  className?: string;
  color?: string;
}

export default class Spinner extends React.PureComponent<IProps> {
  public static defaultProps = {
    color: '#0bc1e6'
  };

  public render() {
    const { className, color } = this.props;

    return (
      <div className={classnames('spinner', className)}>
         <svg className="spinner-icon" viewBox="0 0 32 32">
          <circle
            cx="16"
            cy="16"
            fill="none"
            r="14"
            strokeWidth="3"
            stroke={color}
            opacity={0.6}
          />
          <circle
            cx="16"
            cy="16"
            fill="none"
            r="14"
            strokeWidth="3"
            stroke={color}
            strokeDasharray={80}
            strokeDashoffset={60}
          />
        </svg>
      </div>
    );
  }
}
