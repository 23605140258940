import semver from 'semver';

import { MyAccountConfig } from '../interfaces/ClientInfo';
import { qsConfigs } from './helpers';

type SdkPlatform = 'js' | 'ios' | 'android';

const condition: Record<SdkPlatform, string> = {
  js: '2.0.0-0',
  ios: '6.0.0-0',
  android: '6.0.0-0'
};

const sdkValidators = (platform: SdkPlatform, version: string): boolean => {
  const minVersion = condition[platform];
  if (!minVersion) {
    return false;
  }
  return semver.gte(version, minVersion);
};

export function isMagicLinkEnabled(
  myAccountConfig?: MyAccountConfig | null
): boolean {
  const { enable_magic_link = false } = myAccountConfig || {};
  const { sdkPlatform, sdkVersion, authnMethod } = qsConfigs() as {
    sdkPlatform: SdkPlatform;
    sdkVersion: string;
    authnMethod: string[] | string;
  };

  if (authnMethod) {
    return (
      (Array.isArray(authnMethod) && authnMethod.includes('passwordless')) ||
      authnMethod === 'passwordless'
    );
  }

  if (!enable_magic_link) {
    return false;
  }

  // run directly on browser without sdk
  // TODO: flip to true when magic link is ready for moneytree only guests
  if (!sdkPlatform || !sdkVersion) {
    return false;
  }

  let valid = false;

  try {
    valid = sdkValidators(sdkPlatform, sdkVersion);
  } catch (error) {
    // istanbul ignore next
    // only show warning in non test environment
    if (window.navigator.userAgent.indexOf('jsdom') === -1) {
      console.warn('Magic Link', (error as Error).message);
    }
  }

  return valid;
}
