import React, { ReactElement, lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import appPage from '../../../hoc/appPage';
import dataModel from '../../../models/data';
import toastsModel from '../../../models/toasts';
import { Preset } from '../../../components/Toast';
import { usePartnerConfig } from '../../../contexts/partnerConfig';

const UnavailableView = lazy(() => import('../../../views/UnavailableView'));
const Headers = lazy(() => import('../../../components/Headers'));
const SSOOnboardForm = lazy(() => import('../../../forms/SSO/OnboardForm'));

function SSOOnboardPage(): ReactElement {
  const {
    email,
    country,
    brandingInfo: { clientId }
  } = dataModel;

  const { t } = useTranslation();
  const [canOnboard, setCanOnboard] = useState(false);
  const { config } = usePartnerConfig();
  const {
    oauth_consent_tos_link: oauthConsentTosLink = '',
    oauth_consent_pp_link: oauthConsentPpLink = ''
  } = config?.myAccount || {};

  useEffect(() => {
    if (!config) {
      return;
    }

    // TODO: when all clients provides us with 2 links, we can make this an "&&" condition
    const haveRequiredLinks = oauthConsentTosLink || oauthConsentPpLink;

    // we will not be able to determine tos link existence without `client_id` and `country`
    // hence showing this error only when we can determine it and the value is still missing
    if (clientId && country && !haveRequiredLinks) {
      toastsModel.add({
        text: t('SSOOnboardForm.toast.missingClientTosOrPpLink'),
        preset: Preset.Danger
      });
    }

    setCanOnboard(Boolean(country && haveRequiredLinks && email));
  }, [config]);

  if (!canOnboard) {
    return <UnavailableView />;
  }

  return (
    <div className="sso-onboard-page">
      <Headers />
      <SSOOnboardForm
        oauthConsentTosLink={oauthConsentTosLink}
        oauthConsentPpLink={oauthConsentPpLink}
      />
    </div>
  );
}

export default appPage(SSOOnboardPage);
