import { LanguageDetectorAsyncModule } from 'i18next';
import qs from 'qs';

interface LanguageDetectorInitParam {
  supportedLanguages: string[];
  supportedCountries: string[];
  defaultLanguage: string;
  defaultCountry: string;
}

export default class LanguageDetector {
  public type: LanguageDetectorAsyncModule['type'] = 'languageDetector';
  public async: LanguageDetectorAsyncModule['async'] = true;

  private languages: string[];
  private countries: string[];
  private defaultLanguage: string;
  private defaultCountry: string;

  constructor(params: LanguageDetectorInitParam) {
    const { supportedLanguages, supportedCountries, defaultLanguage, defaultCountry } = params || {};

    if (!supportedLanguages || (Array.isArray(supportedLanguages) && !supportedLanguages.length)) {
      throw new Error('[Language Detector] Must provide at least one value for "supportedLanguages" array.');
    }

    if (!supportedCountries || (Array.isArray(supportedCountries) && !supportedCountries.length)) {
      throw new Error('[Language Detector] Must provide at least one value for "supportedCountries" array.');
    }

    if (!defaultLanguage || supportedLanguages.indexOf(defaultLanguage) === -1) {
      throw new Error(
        '[Language Detector] Must provide a default language, must be a value from "supportedLanguages" array.'
      );
    }

    if (!defaultCountry || supportedCountries.indexOf(defaultCountry) === -1) {
      throw new Error(
        '[Language Detector] Must provide a default country, must be a value from in "supportedCountries" array.'
      );
    }

    this.languages = supportedLanguages;
    this.countries = supportedCountries;
    this.defaultLanguage = defaultLanguage;
    this.defaultCountry = defaultCountry;
  }

  public async detect(cb: (lng: string) => void): Promise<void> {
    let [lang, country] = this.detectFromQueryString();

    if (!lang || !country) {
      const [guestLanguages, guestCountries] = this.guestPreference();

      // fallback to guest preference then system supported default
      lang = lang || guestLanguages[0] || this.defaultLanguage;
      country = country || guestCountries[0] || this.defaultCountry;
    }

    cb(`${lang}-${country}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public init() {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public cacheUserLanguage() {}

  // get query string language and country values
  private detectFromQueryString(): string[] {
    let { locale = '', country = '' } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    locale = (Array.isArray(locale) ? locale[locale.length - 1] : locale) as string;
    country = (Array.isArray(country) ? country[country.length - 1] : country) as string;

    const [qsLanguage, qsCountry = ''] = locale.replace('_', '-').split('-');
    const guestLang = this.languages.indexOf(qsLanguage) === -1 ? '' : qsLanguage;

    let guestCountry = this.countries.indexOf(country) === -1 ? qsCountry : country;
    guestCountry = this.countries.indexOf(guestCountry) === -1 ? '' : guestCountry;

    return [guestLang, guestCountry];
  }

  // get guest preferred locales order from browser
  private guestPreference(): [string[], string[]] {
    const {
      // NOTE: languages doesn't exists in IE11, fallback to supported value
      languages: guestLanguages = [window.navigator.userLanguage || window.navigator.language]
    } = window.navigator;

    const guestLang: string[] = [];
    const guestCountry: string[] = [];

    for (const guestLocale of guestLanguages) {
      const [lang, country] = guestLocale.split('-');

      // not supported language, e.g: en from en or en-AU
      if (this.languages.indexOf(lang) !== -1 && guestLang.indexOf(lang) === -1) {
        guestLang.push(lang);
      }

      // not supported country, e.g: AU from en or en-AU
      if (country && this.countries.indexOf(country) !== -1 && guestCountry.indexOf(country) === -1) {
        guestCountry.push(country);
      }
    }

    return [guestLang, guestCountry];
  }
}
