import 'dayjs/locale/en-au';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import dayjs from 'dayjs';
import { observable, action } from 'mobx';
import i18next, { i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';

import dataModel from '../data';
import { updateQueryString } from '../../lib/helpers';
import LanguageDetector from './language-detector';

const DEFAULT_LANGUAGE = 'en';

const LANGUAGES_RESOURCE = __LOCALIZATIONS__; // value from webpack define plugin

const LANGUAGES = LANGUAGES_RESOURCE.reduce((acc, locale: string) => {
  let [language] = locale.split('-');
  language = language.toLowerCase();

  if (language && acc.indexOf(language) === -1) {
    acc.push(language);
  }

  return acc;
}, [] as string[]);

export class Locale {
  @observable public language = '';
  @observable public country = '';

  private countries: string[] = [];
  private i18next = i18next.use(initReactI18next);

  public get supportedCountries() {
    return this.countries;
  }

  public async init() {
    this.setupI18next();

    await this.i18next.init({
      returnNull: false,
      interpolation: { escapeValue: false }, // not needed for react as it escapes by default
      fallbackLng: DEFAULT_LANGUAGE
    });

    const { language: i18nextLanguage } = this.i18next;
    const language = dataModel.locale.replace('_', '-') || i18nextLanguage;
    const country: string = dataModel.country || i18nextLanguage.split('-')[1];

    await this.update(language, country);
  }

  @action public async update(
    languageLocale: string,
    country: string
  ): Promise<void> {
    const language = languageLocale.split('-')[0]; // e.g: convert en-AU to en

    let locale = `${language}-${country}`;
    locale = LANGUAGES_RESOURCE.indexOf(locale) === -1 ? language : locale;

    if (LANGUAGES_RESOURCE.indexOf(locale) === -1) {
      locale = this.i18next.language;
    }

    await this.i18next.changeLanguage(locale);
    await this.loadLocalizations();

    this.language = this.i18next.language;

    if (this.countries.indexOf(country) !== -1) {
      this.country = country;
    }

    dayjs.locale(this.language.toLowerCase());

    updateQueryString('locale', this.language);
  }

  private setupI18next() {
    this.countries = dataModel.supportedCountries;
    this.country = this.countries[0];

    const languageDetector = new LanguageDetector({
      supportedLanguages: LANGUAGES,
      supportedCountries: this.countries,
      defaultLanguage: DEFAULT_LANGUAGE,
      defaultCountry: this.country
    });
    this.i18next = i18next.use(languageDetector);
  }

  private loadLocalizations(): Promise<(void|i18n)[]> {
    return Promise.all(
      this.i18next.languages.reduce((promises, bundleName: string) => {
        const filename = bundleName.replace('-', '_');

        if (!this.i18next.hasResourceBundle(bundleName, 'translation')) {
          promises.push(
            import(`../../localization/${filename}.json`).then((resource) =>
              this.i18next.addResourceBundle(
                bundleName,
                'translation',
                resource
              )
            )
          );
        }

        return promises;
      }, [] as Array<Promise<void|i18n>>)
    );
  }
}

export default new Locale();
