import React from 'react';
import classnames from 'classnames';

import Img, { Props as ImgProps } from '../Img';

import './styles.scss';

const src = require('../../assets/images/logos/moneytree.png');

type Props = Omit<Omit<ImgProps, 'src'>, 'alt'>;

export default function Sprout(props: Props) {
  const { className, ...rest } = props;

  return (
    <Img
      {...rest}
      className={classnames('moneytree-logo-sprout', className)}
      src={src}
      alt="sprout icon"
    />
  );
}
