import React from 'react';

import { IProps } from '../Icon';
import Sprout from './Sprout';

export default function MoneytreeIcon(props: Omit<IProps, 'icon'>) {
  return (
    <Sprout {...props} />
  );
}
