import { RequestOptions } from 'mixpanel-browser';
import { Base } from './base';

function deleteAccountLanding(this: Base) {
  this.instance?.track('[myAccount]deleteAccountPage_visited', {
    ...this.default_properties
  });
}

function deleteAccountButtonClicked(this: Base) {
  this.instance?.track(
    '[myAccount]deleteAccount_tapped',
    {
      ...this.default_properties
    },
    { send_immediately: true } as RequestOptions
  );
}

export default {
  deleteAccountLanding,
  deleteAccountButtonClicked
};
