import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import Icon from '../Icon';

import './styles.scss';

export default function BackButton(): ReactElement {
  return (
    <Link className="back-button" to={`/settings${window.location.search}`} replace>
      <Icon className="back-arrow" icon="back" />
    </Link>
  );
}
