import React, { ReactElement, useState, useCallback } from 'react';

import { MyAccountConfig, ClientInfo } from '../../../../interfaces/ClientInfo';
import TextButton from '../../../../components/TextButton';
import Accordion from '../Accordion';
import { usePartnerConfigContext } from '../AppInfoContext';
import update from '../update';
import TosLink from './TosLink';

import './styles.scss';

export default function MyAccountPartnerConfig(): ReactElement {
  const { clientId, country, clientInfo } = usePartnerConfigContext();

  const {
    partnerConfigs: { myAccount }
  } = clientInfo as ClientInfo;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [oldMyAccountConfig, setOldMyAccountConfig] = useState(myAccount || {} as MyAccountConfig);
  const [myAccountConfig, setMyAccountConfig] = useState(oldMyAccountConfig);

  const updateConfig = useCallback(() => {
    setIsSubmitting(true);

    update<MyAccountConfig>(clientId, country, 'my_account', myAccountConfig)
      .then((status: boolean) => {
        setIsSubmitting(false);

        if (status) {
          setOldMyAccountConfig(myAccountConfig);
          return;
        }

        setMyAccountConfig(oldMyAccountConfig);
      })
      .catch();
  }, [myAccountConfig]);

  function linkChanged(value: string) {
    setMyAccountConfig({
      ...myAccountConfig,
      oauth_consent_tos_link: value
    });
  }

  function canUpdate() {
    if (isSubmitting) {
      return false;
    }

    if (JSON.stringify(oldMyAccountConfig) === JSON.stringify(myAccountConfig)) {
      return false;
    }

    return true;
  }

  return (
    <Accordion title="My Account" id="my-account">
      <div className="my-account-config">
        <TosLink tosLink={myAccountConfig.oauth_consent_tos_link} onChange={linkChanged} />
        <div className="button-container">
          <TextButton text="Update" disabled={!canUpdate()} onPress={updateConfig} />
        </div>
      </div>
    </Accordion>
  );
}
