import { RequestOptions } from 'mixpanel-browser';
import { Base } from './base';

function consentFormLanding(this: Base) {
  this.instance?.track('[consent]started', {
    ...this.default_properties
  });
}

function consentFormDecisionButtonClicked(this: Base, accepted = false) {
  this.instance?.track(
    '[consent]decided',
    {
      ...this.default_properties,
      accepted
    },
    { send_immediately: true } as RequestOptions
  );
}

export default {
  consentFormLanding,
  consentFormDecisionButtonClicked
};
