import Cookies from 'js-cookie';

import dataModel from '../models/data';
import localeModel from '../models/locale';

type Settings = typeof window.intercomSettings;
interface CustomAttributes {
  last_direct_support_client_id?: string;
  unsubscribed_from_emails?: boolean;
}

class Intercom {
  private settings: Settings & CustomAttributes = {
    app_id: '',
    language_override: '',
    hide_default_launcher: false
  };

  constructor() {
    // Clear intercom cookie
    Object.keys(Cookies.get())
      .filter((key) => key.includes('intercom-'))
      .forEach((key) => Cookies.remove(key));
  }

  public hasClient(): boolean {
    return Boolean(window.Intercom);
  }

  public boot(showLauncher: boolean) {
    const { uid, email, intercomHash } = dataModel;

    this.settings = {
      ...this.settings,
      app_id: dataModel.intercomAppId,
      language_override: localeModel.language,
      hide_default_launcher: !showLauncher
    };

    if (uid && email) {
      this.settings = {
        ...this.settings,
        email,
        user_id: uid,
        user_hash: intercomHash,
        last_direct_support_client_id: dataModel.brandingInfo.clientId.substr(
          0,
          5
        )
      };
    }

    this.client('boot', this.settings);
  }

  public shutdown() {
    this.client('shutdown');
  }

  public open() {
    this.client('show');
  }

  public showHideLauncherButton(
    hideLauncher: Settings['hide_default_launcher']
  ) {
    this.client('update', { hide_default_launcher: hideLauncher } as CustomAttributes);
  }

  public unsubscribeFromEmails(status: boolean) {
    this.client('update', { unsubscribed_from_emails: status } as CustomAttributes);
  }

  private client(_action: string, _options?: Settings | CustomAttributes) {
    if (window.Intercom) {
      window.Intercom.apply(null, arguments);
    }
  }
}

export default new Intercom();
