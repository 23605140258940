import React, {
  ReactElement,
  useEffect,
  useCallback,
  useState,
  Suspense,
  FC,
  lazy
} from 'react';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import toastsModel from '../../models/toasts';
import dataModel from '../../models/data';
import localeModel from '../../models/locale';
import BackToHeaderProvider, {
  BackToHeaderContext,
  IBackToHeaderContext
} from '../../models/backToHeader';
import spinnerOverlayModel from '../../models/spinnerOverlay';
import appConfig from '../../configs/app.json';

import SpinnerOverlay from '../../components/SpinnerOverlay';
import analytics from '../../services/analytics';
import StateRenderManager from '../../components/StateRenderManager';
import HealthStatusProvider from '../../contexts/healthStatus';
import PartnerConfigProvider from '../../contexts/partnerConfig';
import { samlSubjectIdParamCleanUp } from '../../lib/samlSubjectId';

import './styles.scss';

const ErrorView = lazy(() => import('../../views/ErrorView'));
const ToastManager = lazy(() => import('../../components/ToastManager'));

interface AppPageProps {
  payload: string;
}

export default function appPage(component: FC, showDefaultIntercom = true) {
  return function AppPage(props: AppPageProps): ReactElement {
    const { payload } = props;
    const [initReady, setInitReady] = useState(false);
    const [setupFailed, setSetupFailed] = useState(false);
    const [remainingProps, setRemainingProps] = useState({});

    useEffect(() => {
      const { toasts, toastsCode, data, ...rest } = JSON.parse(
        decodeURIComponent(atob(payload))
      );
      setRemainingProps(rest);

      dataModel.setup(data);

      samlSubjectIdParamCleanUp();

      const init = async () => {
        await Promise.all([localeModel.init(), analytics.init()]);

        // toasts
        toastsModel.multiAdd(toasts);
        toastsModel.multiAddCode(toastsCode);

        // if there is no uid, let's reset the analytic state
        // when there is a guest uid, we want to keep the same analytic anonymous id from before login
        // and link it to this guest uid
        if (dataModel.uid) {
          analytics.identify();
        } else {
          analytics.reset();
        }

        setInitReady(true);
      };

      init().catch((error) => {
        console.error('Failed to initialize My Account =>', error);

        setInitReady(true);
        setSetupFailed(true);
      });
    }, []);

    const isDesktop = useMediaQuery({
      minWidth: appConfig.desktopMinWidth,
      minHeight: appConfig.desktopMinHeight
    });

    const renderContent = useCallback(
      (backToHeaderContext: IBackToHeaderContext) => {
        const extraClassName = {
          'page-with-headers': Boolean(backToHeaderContext.backToUrl),
          'no-scroll': spinnerOverlayModel.enable
        };

        return (
          <Suspense fallback={<></>}>
            <div className={classnames('app-page', extraClassName)}>
              <HealthStatusProvider>
                <PartnerConfigProvider>
                  <StateRenderManager showDefaultIntercom={showDefaultIntercom}>
                    {setupFailed ? (
                      <ErrorView />
                    ) : (
                      React.createElement(component, remainingProps)
                    )}
                    <SpinnerOverlay />
                  </StateRenderManager>
                </PartnerConfigProvider>
              </HealthStatusProvider>
            </div>
            <ToastManager />
          </Suspense>
        );
      },
      [setupFailed, remainingProps]
    );

    if (!initReady) {
      return <></>;
    }

    return (
      <Provider
        dataModel={dataModel}
        spinnerOverlayModel={spinnerOverlayModel}
        localeModel={localeModel}
      >
        <BrowserRouter>
          <BackToHeaderProvider isDesktop={isDesktop}>
            <BackToHeaderContext.Consumer>
              {renderContent}
            </BackToHeaderContext.Consumer>
          </BackToHeaderProvider>
        </BrowserRouter>
      </Provider>
    );
  };
}
