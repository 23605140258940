import React, { ReactElement, useState, useCallback, useEffect, ChangeEvent } from 'react';
import qs from 'qs';

import TextInput from '../../../../components/TextInput';
import CountryDropdown from '../../../../components/CountryDropdown';
import Spinner from '../../../../components/Spinner';
import { useDebounce } from '../../../../lib/hooks';
import { usePartnerConfigContext } from '../AppInfoContext';

import './styles.scss';

const loadFailed = (
  <div className="unavailable">
    Invalid client id or service is temporary unavailable, please retry with different client id.
  </div>
);

function clientIdValidation(value: string) {
  return value.length === 64;
}

export default function ClientInfoLoader(): ReactElement {
  const { clientId, country, loading, error, updateClientId, updateCountry, clientInfo } = usePartnerConfigContext();
  const [newClientId, setNewClientId] = useState(clientId);

  useDebounce(newClientId, updateClientId);

  useEffect(() => {
    if (!clientInfo) {
      return;
    }

    const { section } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    window.history.pushState(
      {},
      document.title,
      `${window.location.pathname}${qs.stringify({ client_id: clientId, country, section }, { addQueryPrefix: true })}`
    );
  }, [clientInfo]);

  const onChangeCountry = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.currentTarget;

      updateCountry(value);
    },
    [updateCountry]
  );

  return (
    <div className="client-info-loader">
      <TextInput
        className="client-id-input"
        title="Client Id"
        defaultValue={clientId}
        onChangeText={setNewClientId}
        validation={clientIdValidation}
        message="64 long Alphanumeric characters"
      />
      <CountryDropdown value={country} onChange={onChangeCountry} withCurrency={false} />
      {loading && <Spinner />}
      {!loading && error && loadFailed}
    </div>
  );
}
