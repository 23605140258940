import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import isValidUrl from '../../../../../lib/is-valid-url';
import TextInput from '../../../../../components/TextInput';
import { useDebounce } from '../../../../../lib/hooks';

interface TosLinkProps {
  tosLink: string;
  onChange: (newTosLink: string) => void;
}

export default function TosLink(props: TosLinkProps): ReactElement {
  const { tosLink, onChange } = props;

  const [isLinkValid, setIsLinkValid] = useState(false);
  const [newTosLink, setNewTosLink] = useState(tosLink);

  useEffect(() => setNewTosLink(tosLink), [tosLink]);

  const changeLink = useCallback((value: string, isValid: boolean) => {
    setNewTosLink(value);
    setIsLinkValid(value ? isValid : true); // empty string considered as valid
  }, []);

  useDebounce(newTosLink, (value: string) => {
    if (isLinkValid) {
      onChange(value);
    }
  });

  return (
    <div>
      <h4>Client's Terms of service link</h4>
      <p>Mandatory to use onboard feature. Link will also be displayed in authorize page if available.</p>
      <TextInput
        value={newTosLink}
        onChangeText={changeLink}
        validation={isValidUrl}
        placeholder="https://moneytree.jp"
        message="Invalid link format"
      />
    </div>
  );
}
