import { observable } from 'mobx';

const MAX_SPINNER_SHOW_TIME: number = 60 * 1000; // maximum waiting time

export interface ISpinnerOverlay {
  enable: boolean;
  show(): void;
  hide(): void;
}

export class SpinnerOverlay implements ISpinnerOverlay {
  @observable public enable = false;
  private timeout = 0;

  constructor() {
    window.onpageshow = (e: PageTransitionEvent) => {
      // safari will cache the page last state causing the spinner to keep showing when clicked on back button
      // when the page is loaded with cache, we will hide the spinner overlay
      if (e.persisted) {
        this.hide();
      }
    };
  }

  public show() {
    this.enable = true;

    this.timeout = window.setTimeout(
      () => { this.enable = false; },
      MAX_SPINNER_SHOW_TIME
    );
  }

  public hide() {
    this.enable = false;
    window.clearTimeout(this.timeout);
  }
}

export default new SpinnerOverlay();
