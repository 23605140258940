import React, { ReactElement, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router-dom';

import spinnerOverlayModel from '../../../../models/spinnerOverlay';
import intercom from '../../../../lib/Intercom';
import analytics from '../../../../services/analytics';
import { importantQueryString } from '../../../../lib/importantParams';

import './styles.scss';

const Headers = lazy(() => import(/* webpackChunkName: "Headers" */ '../../../../components/Headers'));
const Menu = lazy(() => import(/* webpackChunkName: "Menu" */ '../../../../components/Menu'));
const TextButton = lazy(() => import(/* webpackChunkName: "TextButton" */ '../../../../components/TextButton'));

const AuthorizedApplicationsView = lazy(() =>
  import(/* webpackChunkName: "AuthorizedApplicationsView" */ '../../../../views/AuthorizedApplicationsView')
);
const ChangeLanguageView = lazy(() =>
  import(/* webpackChunkName: "ChangeLanguageView" */ '../../../../views/ChangeLanguageView')
);
const DeleteAccountView = lazy(() =>
  import(/* webpackChunkName: "DeleteAccountView" */ '../../../../views/DeleteAccountView')
);
const EmailPreferencesView = lazy(() =>
  import(/* webpackChunkName: "EmailPreferencesView" */ '../../../../views/EmailPreferencesView')
);
const UpdateEmailView = lazy(() =>
  import(/* webpackChunkName: "UpdateEmailView" */ '../../../../views/UpdateEmailView')
);
const UpdatePasswordView = lazy(() =>
  import(/* webpackChunkName: "UpdatePasswordView" */ '../../../../views/UpdatePasswordView')
);

interface Props {
  enableIntercom: boolean;
  clientId?: string;
  showLogoutButton: boolean;
}

export default function DesktopContent(props: Props): ReactElement {
  const { enableIntercom, clientId, showLogoutButton } = props;

  const { t } = useTranslation();

  function onLogoutClick(): void {
    if (!window.confirm(t('Logout.confirmation'))) {
      return;
    }

    spinnerOverlayModel.show();

    analytics.reset();

    if (enableIntercom) {
      intercom.shutdown();
    }

    location.href = `/guests/logout${importantQueryString()}`;
  }

  return (
    <>
      <div className="authenticated-page-desktop">
        <Menu className="authenticated-page-menu" />
        <div className="authenticated-page-content">
          <Switch>
            <Route exact path="/settings/update-email" component={UpdateEmailView} />
            <Route exact path="/settings/update-password" component={UpdatePasswordView} />
            <Route exact path="/settings/change-language" component={ChangeLanguageView} />
            <Route exact path="/settings/email-preferences" component={EmailPreferencesView} />
            <Route exact path="/settings/authorized-applications" component={AuthorizedApplicationsView} />
            <Route exact path="/settings/delete-account" component={DeleteAccountView} />
            <Redirect to={`/settings/update-email${importantQueryString()}`} />
          </Switch>
        </div>
      </div>
        <Headers title={t(`Header.${clientId ? 'settings' : 'default'}Title`)}>
          {showLogoutButton &&
            (
              <TextButton
                className="authenticated-page-logout-button"
                text={t('Menu.list.entry.logout')}
                onPress={onLogoutClick}
              />
            )
          }
      </Headers>
    </>
  );
}
