import { RequestOptions } from 'mixpanel-browser';
import { Base } from './base';

function signUpFormLanding(this: Base) {
  this.instance?.track('[registration]started', {
    ...this.default_properties
  });
}

function signUpFormSignUpButtonClicked(this: Base) {
  this.instance?.track(
    '[registration]signupButton_clicked',
    { ...this.default_properties },
    { send_immediately: true } as RequestOptions
  );
}

export default {
  signUpFormLanding,
  signUpFormSignUpButtonClicked
};
