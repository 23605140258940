import React, { ReactElement } from 'react';
import classnames from 'classnames';

import Icon from '../Icon';

import './styles.scss';

export enum Preset {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger'
}

export interface IProps {
  id: string;
  text: string;
  preset?: Preset;
  onClose?: (id: string) => void;
}

export default function Toast(props: IProps): ReactElement {
  const { text, preset = Preset.Primary, onClose, id } = props;

  function close(): void {
    if (onClose) {
      onClose(id);
    }
  }

  return (
    <div className={classnames('toast', preset)}>
      <div className="toast-text">{text}</div>
      <button className="toast-close-button" onClick={close}>
        <Icon className="toast-icon" icon="cross" />
      </button>
    </div>
  );
}
