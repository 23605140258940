import React, {
  ReactElement,
  useEffect,
  lazy,
  useMemo,
  HTMLAttributes
} from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';

import reload from '../../lib/reload';
import dataModel from '../../models/data';
import intercom from '../../lib/Intercom';
import { usePartnerConfig } from '../../contexts/partnerConfig';

import './styles.scss';

const BrandConnection = lazy(() => import('../../components/BrandConnection'));
const AnonymousArea = lazy(() => import('../../components/AnonymousArea'));
const Headers = lazy(() => import('../../components/Headers'));
const TextButton = lazy(() => import('../../components/TextButton'));

function openIntercom() {
  intercom.open();
}

type Props = HTMLAttributes<HTMLDivElement>;

export default function UnavailableView(props: Props): ReactElement {
  const {
    className,
    ...remainingProps
  } = props;
  const { brandingInfo: { clientId } } = dataModel;

  const { t } = useTranslation();
  const { config } = usePartnerConfig();

  useEffect(() => {
    reload.init();
  }, []);

  const intercomButton = useMemo(
    () => (
      <TextButton
        big={true}
        className="unavailable-view-button"
        text={t('UnavailableView.contactSupportButton')}
        onPress={openIntercom}
      />
    ),
    []
  );

  return (
    <AnonymousArea
      {...remainingProps}
      className={classnames(className, 'unavailable-view')}
    >
      <Headers />
      <div className="unavailable-view-content">
        {!clientId && <div className="unavailable-view-image" />}
        {clientId && <BrandConnection clientId={clientId} />}
        <div className="unavailable-view-title">
          {t(`UnavailableView.title`)}{' '}
        </div>
        <p className="unavailable-view-description">
          <Trans i18nKey="UnavailableView.description">
            <br />
          </Trans>
        </p>
        {config?.enableIntercom && Boolean(intercom.hasClient()) && intercomButton}
      </div>
    </AnonymousArea>
  );
}
