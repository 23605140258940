import React, { ReactElement, PropsWithChildren, useContext } from 'react';
import classnames from 'classnames';

import { BackToHeaderContext } from '../../models/backToHeader';

import BackButtonBar from './BackButtonBar';
import Header, { IProps as IHeaderProps } from './Header';

import './styles.scss';

interface IProps extends IHeaderProps {
  backButtonBarOnly?: boolean;
}

export default function Headers(props: PropsWithChildren<IProps>): ReactElement {
  const { backToUrl } = useContext(BackToHeaderContext);
  const { className, backButtonBarOnly = false, ...remainingProps } = props;

  const header = <Header {...remainingProps} />;

  return (
    <div className={classnames('headers', className)}>
      {backToUrl && <BackButtonBar url={backToUrl} />}

      {!backButtonBarOnly && header}
    </div>
  );
}
