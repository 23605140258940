import React, { ReactElement } from 'react';

import './styles.scss';

export default function PageLoading(): ReactElement {
  // since this is a loading page, we want to show it ASAP when we starts rendering,
  // use svg here so that it bundles with the javascript instead of waiting to load external image again
  return (
    <div className="page-loading">
      <svg id="loading-indicator" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="#ff0000" d="M659.68 434.46c-56.64 80.3-70.86 177.82-68.12 275.44C687.48 710 772 680.62 838 606c92.44-104.28 90-236.22 90-292-121.7-10.66-217.82 48.18-268.32 120.46zm-263.56-210.7C321.18 157.34 224.38 116 96.82 116.2c-2.34 124-5.76 308.58 85.06 414.68 53.48 62.6 116.18 91.14 192 119.64V907.8H552V710c0-338-65.08-406-155.88-486.24z"/></svg>
    </div>
  );
}
