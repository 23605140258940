import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  HTMLAttributes
} from 'react';
interface HealthStatus {
  maintenance: boolean;
  error: boolean;
}

const defaultStatus: HealthStatus = {
  maintenance: false,
  error: false
};

const HeathStatusContext = createContext({
  ...defaultStatus,
  setStatus: () => null
} as HealthStatus & { setStatus: Dispatch<SetStateAction<Partial<HealthStatus>>> });

export function useHealthStatus() {
  return useContext(HeathStatusContext);
}

export default function HealthStatusProvider(props: HTMLAttributes<HTMLElement>) {
  const { children } = props;
  const [{ maintenance: maintenanceStatus, error: errorStatus }, setStatus] =
    useState(defaultStatus);

  const statusSetter = useCallback(
    ({ maintenance = maintenanceStatus, error = errorStatus }) => {
      setStatus({ maintenance, error });
    },
    [maintenanceStatus, errorStatus]
  );

  const value = {
    maintenance: maintenanceStatus,
    error: errorStatus,
    setStatus: statusSetter
  };

  return (
    <HeathStatusContext.Provider value={value}>
      {children}
    </HeathStatusContext.Provider>
  );
}
