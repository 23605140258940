import mixpanel, { Mixpanel } from 'mixpanel-browser';
import dataModel from '../../models/data';
import { qsConfigs } from '../../lib/helpers';
import shortClientId from '../../lib/shortClientId';

export class Base {
  protected instance?: Mixpanel;
  protected default_properties: {
    appUID?: string;
    sdkVersion?: string;
    sdkPlatform?: string;
  } = {};

  public init(): Promise<void> {
    const {
      analytics: { token, debug }
    } = dataModel;

    return new Promise((resolve) => {
      if (!token) {
        return resolve();
      }

      mixpanel.init(token, {
        debug,
        loaded: (mixpanelInstance) => {
          this.instance = mixpanelInstance;

          const configs = qsConfigs();

          this.default_properties = {
            appUID:
              shortClientId(
                dataModel.cobrandClientApp.uid || dataModel.clientApp.uid
              ) || undefined,
            sdkVersion: configs.sdkVersion as string,
            sdkPlatform: configs.sdkPlatform as string
          };

          resolve();
        }
      });
    });
  }

  get distinctId() {
    return this.instance?.get_distinct_id();
  }

  public reset() {
    this.instance?.reset();
  }

  public identify() {
    if (dataModel.uid) {
      this.instance?.identify(dataModel.uid);
    }
  }
}
