import React, { ReactElement, lazy } from 'react';

import adminPage from '../../../hoc/adminPage';
import { usePartnerConfigContext } from './AppInfoContext';

import './styles.scss';

const Header = lazy(() => import(/* webpackChunkName: "Header" */ '../../../components/Headers/Header'));
const TextButton = lazy(() => import(/* webpackChunkName: "TextButton" */ '../../../components/TextButton'));
const ToastManager = lazy(() => import(/* webpackChunkName: "ToastManager" */ '../../../components/ToastManager'));
const ClientInfoLoader = lazy(() => import(/* webpackChunkName: "LoadClientInfoUi" */ './ClientInfoLoader'));
const ClientInfo = lazy(() => import(/* webpackChunkName: "ClientInfo" */ './ClientInfo'));
const AppInfoContext = lazy(() => import(/* webpackChunkName: "AppInfoContext" */ './AppInfoContext'));
const MyAccountConfig = lazy(() => import(/* webpackChunkName: "MyAccountConfig" */ './MyAccountConfig'));
const UniversalVaultConfig = lazy(() =>
  import(/* webpackChunkName: "UniversalVaultConfig" */ './UniversalVaultConfig')
);

function onPressLogout() {
  window.location.href = '/admin/logout';
}

function onPressAppList() {
  window.location.href = '/oauth/applications';
}

function Content(): ReactElement {
  const { clientInfo } = usePartnerConfigContext();

  return clientInfo ? (
    <>
      <hr />
      <ClientInfo />
      <MyAccountConfig />
      <UniversalVaultConfig />
    </>
  ) : (
    <></>
  );
}

function PartnerConfigurationPage(): ReactElement {
  return (
    <div className="partner-configuration-page">
      <Header>
        <div className="right-content">
          <TextButton className="app-list-button" text="Applications" onPress={onPressAppList} />
          <TextButton className="logout-button" text="Logout" onPress={onPressLogout} />
        </div>
      </Header>
      <h2 className="title">Partner Configuration</h2>
      <div className="content">
        <AppInfoContext>
          <ClientInfoLoader />
          <Content />
        </AppInfoContext>
      </div>
      <ToastManager />
    </div>
  );
}

export default adminPage(PartnerConfigurationPage);
