import { RequestOptions } from 'mixpanel-browser';
import { Base } from './base';

function backToButtonClicked(this: Base, backToUrl: string) {
  this.instance?.track(
    '[myAccount]backTo_clicked',
    {
      ...this.default_properties,
      triggerPath: window.location.pathname,
      backToUrl
    },
    { send_immediately: true } as RequestOptions
  );
}

export default {
  backToButtonClicked
};
