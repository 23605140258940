import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  HTMLAttributes
} from 'react';

import dataModel from '../models/data';
import localeModel from '../models/locale';
import { isInIframe } from '../lib/iframe';

import { PartnerConfig } from '../interfaces/ClientInfo';
import { useHealthStatus } from './healthStatus';

export const defaultConfigs = {
  disableRememberMe: true,
  enableIntercom: false,
  maintenanceMode: false,
  myAccount: {
    oauth_consent_tos_link: '',
    oauth_consent_pp_link: '',
    enable_magic_link: false
  }
};

interface IPartnerConfig {
  config?: PartnerConfig;
  isLoaded: boolean;
  setConfigCountry: Dispatch<SetStateAction<string>>;
}

export const PartnerConfigContext = createContext<IPartnerConfig>({
  config: undefined,
  isLoaded: false,
  setConfigCountry: () => null
});

export function usePartnerConfig() {
  return useContext(PartnerConfigContext);
}

export default function PartnerConfigProvider(
  props: HTMLAttributes<HTMLElement>
) {
  const { children } = props;

  const { setStatus } = useHealthStatus();
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    country,
    brandingInfo: { clientId },
    isExternalClient,
    customUi: { allowIframeMode }
  } = dataModel;
  const [configCountry, setConfigCountry] = useState(
    country || localeModel.country
  );

  const [config, setConfig] = useState<PartnerConfig>({
    ...defaultConfigs,
    // always show intercom for mt client and external client based on config or none
    enableIntercom: !isExternalClient,
    // disable as default for external, reason is if we failed to load a partner config,
    // it is better to no show remember me UI just in case some of them set to disabled
    disableRememberMe: isExternalClient
  } as PartnerConfig);

  useEffect(() => {
    const id = clientId || 'global';
    const url = `${dataModel.omoikaneUrl.replace(
      '{country}',
      configCountry
    )}/api/v1/client-app-info/${id}.json`;

    fetch(url, {
      method: 'GET',
      headers: { 'mt-app-name': 'my-account' }
    })
      .then((response) => {
        const { status } = response;

        if (status === 503) {
          setStatus({ maintenance: true });
          return;
        }

        if (status >= 500 && status < 600) {
          setStatus({ error: true });
          return;
        }

        return response.json().then(({ partnerConfigs }) => {
          // omoikane should return intercom true as default when it is global client id
          if (id === 'global') {
            partnerConfigs.enableIntercom = true;
          }

          // we don't want to display intercom when loaded in iframe,
          // the rational is if you load myaccount in an iframe,
          // you probably have another support tooling in the parent app itself
          if (allowIframeMode && isInIframe()) {
            partnerConfigs.enableIntercom = false;
          }

          setConfig({ ...config, ...partnerConfigs });
          setIsLoaded(true);
        });
      })
      .catch(() => {
        setStatus({ error: true });
        setIsLoaded(true);
      });
  }, [configCountry, clientId]);

  return (
    <PartnerConfigContext.Provider
      value={{ config, isLoaded, setConfigCountry }}
    >
      {children}
    </PartnerConfigContext.Provider>
  );
}
