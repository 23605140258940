import React, { ReactElement } from 'react';

import Img from '../../../../components/Img';
import { getOauthClientImage } from '../../../../lib/helpers';
import { ClientInfo } from '../../../../interfaces/ClientInfo';
import { usePartnerConfigContext } from '../AppInfoContext';

import './styles.scss';

export default function ClientInformation(): ReactElement {
  const { clientId, clientInfo } = usePartnerConfigContext();
  const { name, isMtClient } = clientInfo as ClientInfo;

  return (
    <div className="client-info">
      <Img className="client-logo" src={getOauthClientImage(clientId)} />
      <table className="info">
        <tbody>
          <tr className="row">
            <th className="title">Client Id</th>
            <td className="content">{clientId}</td>
          </tr>
          <tr className="row">
            <th className="title">Default Name</th>
            <td className="content">{name}</td>
          </tr>
          <tr className="row">
            <th className="title">Mt Client</th>
            <td className="content">{isMtClient.toString()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
