import React from 'react';
import classnames from 'classnames';
import Button, { IProps as IButtonProps, Preset } from '../Button';

import './styles.scss';

interface IProps extends IButtonProps {
  big?: boolean; // button size
  text?: string;
}

export default function TextButton(props: IProps) {
  const { children, className, big = false, preset = Preset.Primary, text, ...remainingProps } = props;

  return (
    <Button {...remainingProps} className={classnames('text-button', { big }, className)} preset={preset}>
      {React.Children.count(children) > 0 ? children : text}
    </Button>
  );
}

