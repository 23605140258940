import React from 'react';
import classnames from 'classnames';
import { observer, inject } from 'mobx-react';

import Spinner, { IProps as ISpinnerProps } from '../Spinner';

import { ISpinnerOverlay } from '../../models/spinnerOverlay';

import './styles.scss';

interface IInjectedProps extends ISpinnerProps {
  spinnerOverlayModel: ISpinnerOverlay;
}

@inject('spinnerOverlayModel')
@observer
export default class SpinnerOverlay extends React.Component<ISpinnerProps> {
  public static defaultProps = {
    ...Spinner.defaultProps
  };

  get injectedProps() {
    return this.props as IInjectedProps;
  }

  public render() {
    const { className, color } = this.props;

    return this.injectedProps.spinnerOverlayModel.enable && (
      <div
        className={classnames('spinner-overlay', className)}
        onClick={this.onPress}
      >
        <Spinner className="spinner-overlay-spinner" color={color} />
      </div>
    );
  }

  private onPress = (e: React.MouseEvent<HTMLDivElement>) => e.preventDefault();
}
